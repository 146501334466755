<template>
  <div>
    <fe-dialog
        v-if="viewMissingGuardianModal && !dismissed"
        data-test="missing-guardian-email-modal"
        :header="false"
        :footer="false"
        persistent
        width="70vw"
    >
        <div class="header-container">
            <h2 class="mb-6 mt-8">Missing Guardian Emails</h2>
            <v-btn class="mt-4" icon @click="dismissed = true">
                <v-icon style="font-size: 16px;">fal fa-times</v-icon>
            </v-btn>
        </div>
        <p>
          The following {{ missingContactsCount }} students either have no guardian email available and/or a guardian email address is invalid and cannot be used. You can print their smartFORMS to send home or choose to 
          <a href="https://educlimber.illuminateed.com/hc/en-us/articles/4403006399771-Upload-Student-Guardians" target="_blank" data-test="update-email-documentation-btn">update email addresses.</a>
        </p>
        <fe-grid
          style="height: 56vh; width: 100%;"
          class="flex-grow-1 grid"
          :columnDefs="missingGuardianColumnDefs"
          :rowData="missingGuardianRowData"
          domLayout="normal"
          :showAddRowBtn="false"
          :showToolbarMenu="false"
          :showScrollbars="true"
          :searchBar="false"
        >
        </fe-grid>
        <div class="text-center mt-2">
           <fe-btn usage="primary" @click="printDownloadAllFiles" data-test="download-smartforms-missing-contacts-btn">Print/Download All Files</fe-btn>
        </div>
    </fe-dialog>

    <h3 style="font-size: 18px; margin-bottom: 4px;">Review Students, Guardians, & Forms</h3>
    <notification
      v-if="missingContactsCount > 0 && hasGuardianEmail"
      fontColor="#BF0D00"
      leftColor="#FF8D85"
      messageBgColor="#F8D1CE"
    >
      There are no valid guardian emails available for {{ missingContactsCount }} of the selected student(s). 
      <a href="#" style="color: #BF0D00;" @click.prevent="openDialog" data-test="view-student-forms-without-contacts-btn">View students</a>
    </notification>
    <notification
      v-if="!hasGuardianEmail"
      fontColor="#BF0D00"
      leftColor="#FF8D85"
      messageBgColor="#F8D1CE"
    >
    There are no valid guardian emails available for the selected student(s). 
  </notification>
    <div>
      Review the students, guardian contacts, and forms to send below. Email addresses for guardians are from the
      <a target="_blank" href="https://educlimber.illuminateed.com/hc/en-us/articles/4403006399771-Upload-Student-Guardians">Student Guardian Upload.</a>
    </div>
    <div style="margin-top: 32px;">
      Students: <span class="count" v-text="studentsCount"></span>
      Guardian Contacts: <span class="count" v-text="guardianContactsCount"></span>
      smartFORMS: <span class="count" v-text="smartformsCount"></span>
    </div>
    <fe-grid
      style="height: 56vh; margin-top: -38px; width: 90vw;"
      class="flex-grow-1 grid"
      :columnDefs="columnDefs"
      :rowData="sendingRowData"
      domLayout="normal"
      :showAddRowBtn="false"
      :showDownload="false"
      :showToolbarMenu="false"
      :showScrollbars="true"
      :searchBar="false"
    >
    </fe-grid>
    <fe-btn
      data-test="review-guardians-continue-btn"
      style="margin-top: 16px; margin-left: -8px;"
      usage="primary"
      @click="$emit('goToCommunicationSettings')"
    >
      Continue
    </fe-btn>
  </div>
</template>

<script>
  import Notification from './notification.vue'
  import SmartFormMixin from '../smartFormMixin'

  export default {
    name: 'ReviewEmail',
    components: {
        Notification,
    },
    mixins: [SmartFormMixin],
    props: {
      studentsCount: {
        type: Number,
        required: true
      },
      guardianContactsCount: {
        type: Number,
        required: true
      },
      smartformsCount: {
        type: Number,
        required: true
      },
      columnDefs: {
        type: Array,
        required: true
      },
      sendingRowData: {
        type: Array,
        required: true
      },
      missingContactsCount: {
        type: Number,
        required: true
      },
      missingGuardianRowData: {
        type: Array,
        required: true
      },
      hasGuardianEmail: {
        type: Boolean,
        default: true
      },
      missingContactSelections: {
        type: Array,
        required: true
      }
    },
    data() {
      return {
        dismissed: false,
        viewMissingGuardianModal: false,
      }
    },
    computed: {
      missingGuardianColumnDefs() {
        return this.columnDefs.filter(col => col.field !== 'studentFormCount')
      }
    },
    methods: {
      printDownloadAllFiles() {
        this.downloadFormZip(this.missingContactSelections, null, false, null)
        this.viewMissingGuardianModal = false
      },
      openDialog() {
        this.dismissed = false
        this.viewMissingGuardianModal = true
      }
    },
    mounted() {
      this.dismissed = false
    },
    watch: {
      missingContactSelections(v) {
      }
    },
  }
</script>

<style scoped>
  .count {
    font-size: 20px;
    font-weight: bold;
  }

  .header-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
</style>
