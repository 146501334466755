<template>
    <fe-dialog
        v-if="localValue"
        persistent
        title="User Preferences"
        @dismiss="localValue=false"
        @close="localValue=false"
        :acceptButtonDisabled="!canSave"
        @accept="doSave"
        acceptButtonText="Save"
    >
        <v-form>
            <fe-crud

            <div v-for="field in fieldConfig" class="pr-2">
                <fe-label v-if="field.label">{{field.label}}</fe-label>
                <component
                    :is="field.component"
                    v-bind="field.attrs"
                    v-model="record[field.field]"
                />
            </div>
        </v-form>
    </fe-dialog>
</template>

<script>
    import { mapState } from 'vuex'

    export default {
        name: 'UserPreferences',
        props: ['value'],
        computed: {
            ...mapState('global', ['sessionUser', 'currentYear']),
            canSave() {
                return this.$isDirty([this.orig], [this.record])
            },
            fieldConfig() {
                let me = this

                let items = [{
                    field: 'TOP_SECRET_MODE',
                    label: 'Top Secret Mode',
                    component: 'fe-switch'
                }, {
                    field: 'DEFAULT_SCHOOL',
                    label: 'Default School',
                    component: 'fe-remote-combo',
                    width: '100%',
                    attrs: {
                        url: "schools.php?action=get&incl_groups=1&school_year_id="+this.currentYear.id,
                        rootProperty: 'schools',
                        itemValue: 'id',
                        itemText: 'name',
                        byId: true
                    }
                }, {
                    field: 'DATAWALL_CONFIG',
                    label: 'Default Data Wall',
                    component: 'fe-remote-combo',
                    width: '100%',
                    attrs: {
                        url: "dataWallConfig.php?action=get&property=config&user_id="+this.sessionUser.user.id,
                        rootProperty: 'data_wall_configs',
                        itemValue: 'id',
                        itemText: 'name',
                        byId: true
                    }
                }, {
                    field: 'MINMAXLINES',
                    label: 'Show Min/Max Lines on Benchmark Charts',
                    component: 'fe-switch'
                }]

                // Google Calendar preference should only appear if feature is active for district and user
                if (this.gcalEnabled) {
                    items.push({
                        field: 'GOOGLE_CALENDAR_COMPONENTS',
                        label: 'Google Calendar Sync',
                        component: 'fe-remote-combo',
                        width: '100%',
                        attrs: {
                            //url: "schools.php?action=get&incl_groups=1&school_year_id="+this.currentYear.id,
                            //rootProperty: 'schools',
                            items: this.gcalItems,
                            itemValue: 'value',
                            itemText: 'name',
                            byId: true
                        }
                    })
                }

                return items
            }
        },
        watch: {
            value: {
                handler(v) {
                    if (v) {
                        this.loadPrefs()
                    }
                    this.localValue = v

                }
            },
            localValue(v) {
                this.$emit('input', v)
            },
            'record.GOOGLE_CALENDAR_COMPONENTS'(v) {
                // If user is saying they want Google Calendar, then prompt them to
                // allow that scope if they haven't previously granted it
                if (v == 'rememberYes') {
                    this.$linkGoogleAccount(this.sessionUser)
                }
            },
        },
        data() {
            return {
                localValue: null,
                record: {},
                orig: {},
                topSecret: undefined,
                gcalItems: [
                    { value: 'rememberYes', name: 'Yes, Sync Always' },
                    { value: 'ask', name: 'Ask When Creating Events' },
                    { value: 'rememberNo', name: 'No, Never Sync' }
                ],
                gcalEnabled: false
            }
        },
        mounted() {
            this.localValue = this.$props.value
        },
        methods: {
            done() {
                this.record = {}
                this.localValue = false
            },
            doSave() {
                let rec = this.$_.cloneDeep(this.record)
                rec.user_id = this.sessionUser.user.id
                let topSecretChanged = this.topSecret !== !!rec.TOP_SECRET_MODE
                this.$axios.post('preferences.php?action=update', JSON.stringify(rec))
                    .then(resp => {
                        if (this.$ecResponse(resp)) {
                            if (topSecretChanged) {
                                location.reload()
                            } else {
                                // Force reload of user preferences to update default school filters
                                this.$store.dispatch('global/loadUserPreferences')

                                // Reload page so that new default school is force-applied across entire app
                                window.location.reload(true)
                            }
                        }
                    })
            },
            loadPrefs() {
                let r = {
                    user_id: this.sessionUser.user.id,
                    // Prefer a default value for GC for watch() change tracking
                    // if user has not previously saved a preference for it
                    GOOGLE_CALENDAR_COMPONENTS: null
                }
                // Google calendar check
                this.$verifyGoogleLogin()
                    .then(response => {
                        if (response) {
                            this.gcalEnabled=!!response.isGoogleSession
                        }
                    })

                this.$modelGet('userPreferences')
                    .then(resp => {
                        resp.forEach(pref => {
                            r[pref.code] = parseInt(pref.user_value)
                            if (pref.code === 'TOP_SECRET_MODE') this.topSecret = !!parseInt(pref.user_value)
                            if (pref.code === 'GOOGLE_CALENDAR_COMPONENTS') r[pref.code] = pref.user_value || null // This will be a sTring, don't parseInt on it
                        })
                        this.record = r
                        this.orig = {...r}
                    })
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>
