<template>
    <div class="d-flex flex-fill flex-column pa-4" style="overflow: hidden;">
        <div class="d-flex">
            <div class="page-title">
                Rate
            </div>

            <fe-tooltip
                tooltip="Click a data point to drilldown for absence counts, student data list and additional line charts"
                style="display:inline-block;"
                class="mt-4 ml-4"
            >
                <v-icon class="pb-2" style="font-size: 12px;">fas fa-question-circle</v-icon>
            </fe-tooltip>

            <fe-tooltip
                tooltip="Full Day Rate: The percentage of time a student is present for the entire day (no absences reported).  SIS Reported Rate: Rate provided by SIS (not available from some SIS)"
                style="display:inline-block;"
                class="mt-4 ml-4"
            >
                <v-icon class="pb-2" style="font-size: 12px;">fas fa-info-circle</v-icon>
            </fe-tooltip>

            <v-spacer/>
            <fe-btn
                v-if="view==='charts' && editSearchBtn"
                usage="secondary"
                class="mt-n1"
                @click="searchDialog.show=true"
            >
                Edit Search
            </fe-btn>

            <div class="d-flex toggle-button">
                <div v-ripple class="show-charts first" :class="{selected: view === 'charts'}" @click="view = 'charts'">
                    <v-icon class="mr-2">fas fa-chart-bar</v-icon>
                    Charts
                </div>

                <div v-ripple class="show-datawall" :class="{selected: view === 'datawall'}" @click="view = 'datawall'">
                    <v-icon class="mr-2">fas fa-table</v-icon>
                    Datawall
                </div>
            </div>
        </div>

        <div v-if="view === 'charts'" class="d-flex flex-fill flex-column">
            <v-tabs v-model="activeTab" class="ec-tabs">
                <v-tab class="ec-tabs-small" v-for="(t, i) in tabs" :key="`att-tab-`+i">{{ t.title }}</v-tab>
            </v-tabs>

            <v-carousel
                v-model="activeTab"
                ref="carouselContainer"
                class="mt-2"
                height="100%"
                :show-arrows="false"
                hide-delimiters
                light
            >
                <v-carousel-item v-for="(c,i) in charts" :key="`chart-`+i" class="ma-4">
                    <div v-if="c.loaded">
                        <attendance-chart
                            refs="attendanceChart"
                            :config="c.config"
                            :chartParams="c.chartParams"
                            :property="c.property"
                            :appliedFilters="appliedFilters"
                            @chartClicked="doClick"
                        />
                    </div>

                </v-carousel-item>
            </v-carousel>
        </div>

        <attendance-datawall v-if="view === 'datawall'" :params="datawallParams"/>

        <div
            v-if="view === 'charts'"
            class="attendance-breadcrumb"
        >
            <breadcrumb v-model="navigation" class="mt-2" @click="doBreadcrumb"/>
        </div>

        <v-dialog
            v-model="chartClick.show"
            content-class="elevation-0"
            :transition="false"
            overlay-opacity=".2"
            width=190
        >
            <v-card :style="{top: chartClick.y, left: chartClick.x}" class="chart-dialog" width=190 flat dense>
                <v-list dense>
                    <v-list-item @click="doPeriod">View Absence Counts</v-list-item>
                </v-list>
            </v-card>
        </v-dialog>

        <fe-dialog
            v-if="searchDialog.show"
            title="Edit Search"
            dismissButtonText="Cancel"
            acceptButtonText="Search"
            :acceptButtonDisabled="!searchDialog.params.school_year_id"
            persistent
            @accept="updateSearch"
            @close="searchDialog.show=false"
        >
            <div>
                <fe-remote-combo
                    label="School Year"
                    v-model="searchDialog.params.school_year_id"
                    itemText="name"
                    itemValue="id"
                    rootProperty="years"
                    :url="$models.getUrl('schoolYear', 'read')"
                    :multiple="false"
                    byId
                />

                <fe-remote-combo
                    label="School"
                    v-model="searchDialog.params.school_id"
                    itemText="name"
                    itemValue="id"
                    rootProperty="schools"
                    :url="$models.getUrl('school', 'read')"
                    multiple
                    byId
                />

                <fe-remote-combo
                    label="Grade"
                    v-model="searchDialog.params.grade_id"
                    itemText="name"
                    itemValue="id"
                    rootProperty="grades"
                    :url="$models.getUrl('grade', 'read')"
                    multiple
                    byId
                />

                <fe-remote-combo
                    label="Gender"
                    v-model="searchDialog.params.gender"
                    itemText="gender"
                    itemValue="gender"
                    rootProperty="genders"
                    url="filters.php?action=get&filter=gender"
                    multiple
                    byId
                />

                <fe-remote-combo
                    label="Ethnicity"
                    v-model="searchDialog.params.ethnicity"
                    itemText="ethnicity"
                    itemValue="ethnicity"
                    rootProperty="ethnicities"
                    url="filters.php?action=get&filter=ethnicity"
                    multiple
                    byId
                />

                <div class="d-flex">
                    <fe-remote-combo
                        label="Active Status"
                        v-model="searchDialog.params.student_active_flag"
                        itemText="name"
                        itemValue="id"
                        class="mr-2"
                        :items="[{id: '0', name: 'Inactive'}, {id: '1', name: 'Active'}, {id: null, name: 'Both'}]"
                        :multiple="false"
                        byId
                    />

                    <div>
                        <fe-label>Active As Of</fe-label>
                        <fe-date-picker v-model="searchDialog.params.student_active_date"/>
                    </div>
                </div>
            </div>
        </fe-dialog>
    </div>
</template>

<script>
import AttendanceChart from '@/components/charts/AttendanceChart'
import AttendanceDatawall from './AttendanceDatawall'
import Breadcrumb from '@/components/common/Breadcrumb'
import {mapState} from 'vuex'

export default {
    name: 'AttendanceReporting',

    components: {AttendanceChart, AttendanceDatawall, Breadcrumb},

    props: {
        params: {
            type: Object
        },
        editSearchBtn: {
            type: Boolean,
            default: true
        }
    },

    data() {
        return {
            activeTab: null,
            charts: [],
            tabs: [],
            view: 'charts',
            navigation: [],
            chartClick: {
                show: false,
                x: 0,
                y: 0,
                meta: {}
            },
            lastParams: {},
            searchDialog: {
                show: false,
                params: {}
            },
            appliedFilters: [],
        }
    },

    computed: {
        ...mapState('global', ['sessionUser', 'currentYear']),

        height() {
            let c = this.$refs.carouselContainer.$el.getBoundingClientRect()
            return parseInt(c.height) - 40
        },

        datawallParams() {
            let p = {}
            this.navigation.forEach(n => Object.assign(p, n.params))
            return p
        }
    },

    watch: {
        activeTab(n, o) {
            if (n && (n !== o)) {
                this.loadTab()
            }
        }
    },

    mounted() {
        let p = this.$_.cloneDeep(this.params) || {school_year_id: this.currentYear.id}
        this.navigation = [{
            id: 'base',
            name: 'Attendance Rate',
            params: p
        }]
        this.searchDialog.params = p
        this.lastParams = p

        this.getTabs(p)
    },

    methods: {
        updateSearch() {
            this.navigation = [{
                name: 'Custom Search',
                params: {...this.searchDialog.params}
            }]

            this.getTabs({...this.searchDialog.params})
        },

        doBreadcrumb(crumb) {
            this.lastParams = crumb.params
            this.getTabs(crumb.params)
        },

        doPeriod() {
            this.$store.commit('global/addDockableWindow', {
                name: 'Absence Counts',
                component: 'period-counts',
                attrs: {
                    params: {...this.chartClick.meta.params},
                    allCharts: true
                }
            })

            this.chartClick = {
                show: false,
                meta: {}
            }
        },

        doClick(evt, rec) {
            let w = this.$refs.carouselContainer.$el.getBoundingClientRect().width
            this.chartClick.title = evt.point.category.substring(0, 20) + (evt.point.category.length > 20 ? '...' : '')
            this.chartClick.show = true
            if (w - evt.x > -100) {
                this.chartClick.x = evt.x + 'px'
            } else {
                // move menu if it will go off the screen
                this.chartClick.x = (evt.x - 190) + 'px'
            }
            this.chartClick.y = (evt.y + 10) + 'px'
            this.chartClick.meta = {
                evt: evt,
                params: {...this.$props.params, ...rec.params}
            }
        },

        getTabs(params) {
            this.tabs = []
            this.charts = []

            this.$modelGet('attendanceChartTypes', params)
                .then(response => {
                    response.forEach((chart, index) => {
                        this.tabs.push({
                            index: index,
                            title: chart.title
                        })

                        this.charts.push({
                            name: chart.title,
                            config: chart,
                            chartParams: chart.params,
                            chartType: chart.chartType,
                            index: index,
                            loaded: false
                        })
                    })

                    this.activeTab = 0
                    this.lastParams = params
                    this.loadTab()
                })
        },

        loadTab() {
            let tabChart = this.charts[this.activeTab]

            if (tabChart && !tabChart.loaded) {
                this.$setLoading(true)
                let params = tabChart.chartParams
                this.$modelGet('attendanceCharts', params)
                    .then(res => {
                        if (res.length) {
                            this.charts[this.activeTab].config = res[0]
                            tabChart.loaded = true
                        }
                    })
                    .finally(() => {
                        this.$setLoading(false)
                        this.subtitleFilters()
                    })
            }
        },

        subtitleFilters() {
            if (this.charts.length > 0) {
                let filters = this.charts[0].config.filters_applied // only need applied filters from one chart
                if (filters) {
                    this.appliedFilters = filters.split('|').filter(f => f !== '')
                } else {
                    this.appliedFilters = []
                }
            }

        },
    }
}
</script>

<style lang="scss" scoped>
@import "../../../style/educlimber.scss";

.chart-dialog {
    z-index: 10;
    position: absolute;
}

.toggle-button {
    background: #FFFFFF;
    border: 1px solid #E0E1E6;
    border-radius: 4px;
    height: 36px;

    .first {
        border-right: 1px solid #E0E1E6;
    }

    .selected {
        background: var(--fe-accent);
    }

    div {
        width: 95px;
        text-align: center;
        line-height: 34px;
        cursor: pointer;
        color: #050F2D !important;

        ::v-deep .v-icon {
            color: #050F2D;
        }
    }
}

.attendance-breadcrumb {
    position: absolute;
    width: 98%;
    border-top: 1px solid #dddddd;
    bottom: 0px;
    background: white;
    padding: 4px 8px 4px 16px;
}
</style>
