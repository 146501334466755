import SmartForm from '@/components/modules/smartforms/SmartForm'
import List from '@/components/modules/smartforms/List'
import SmartForms from '@/components/modules/smartforms/Index'
import GuardianEmailSetup from '@/components/modules/smartforms/guardianEmail/Index'
import GuardianEmailReporting from '@/components/modules/smartforms/guardianEmailReporting/Index'


export default [{
    path: '/SmartForm/:folderId/:id',
    props: true,
    name: 'smartFORM',
    component: SmartForm,
    meta: {
        navigation: 'launchpad',
        routes: ['/', '/SmartForms']
    }
}, {
    path: '/SmartForm/:id',
    props: true,
    name: 'smartFORM',
    component: SmartForm,
    meta: {
        navigation: 'launchpad',
        routes: ['/', '/SmartForms']
    }
}, {
    path: '/SmartForms/Student/:studentId',
    props: true,
    name: 'Student smartFORMS',
    component: List,
    meta: {
        navigation: 'launchpad',
        routes: ['/', '/SmartForms']
    }
}, {
    path: '/SmartForms',
    name: 'SmartForms',
    component: SmartForms,
    meta: {
        navigation: 'launchpad',
        routes: ['/']
    }
}, {
    path: '/SmartForms/:view',
    props: true,
    name: 'Folder',
    component: SmartForms,
    meta: {
        navigation: 'launchpad',
        routes: ['/', '/SmartForms']
    }
}, {
    path: '/SmartForms/guardianEmail',
    name: 'Guardian Email Setup',
    component: GuardianEmailSetup
}, {
    path: '/SmartForms/guardianReporting',
    name: 'Guardian Email Reporting',
    component: GuardianEmailReporting
}]