<template>
  <div :style="{ color: fontColor, backgroundColor: messageBgColor, borderLeftColor: leftColor }" class="notification">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'Notification',
  props: {
    fontColor: {
      type: String,
      default: '#000'
    },
    leftColor: {
      type: String,
      default: '#000'
    },
    messageBgColor: {
      type: String,
      default: '#fff'
    }
  }
}
</script>

<style scoped>
  .notification {
    padding: 8px;
    border-left: 4px solid;
    margin-bottom: 16px;
    margin-top: 16px;
    margin-left: -8px;
    font-size: 14px;
  }
</style>
