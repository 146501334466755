<template>
    <div
        class="flex-fill flex-column no-scroll"
        style="position:relative; width: auto;"
        v-intersect="onShow"
        data-test="student-smartforms-overlay"
    >
        <div v-if="title" class="page-title">{{title}}</div>
        <!-- Add to folder dialog -->
        <fe-dialog
            v-if="selectFolderDialog.show"
            title="Move to Folder"
            :acceptButtonDisabled="!selectFolderDialog.record"
            @dismiss="selectFolderDialog.show = false;"
            @close="selectFolderDialog.show = false;"
            @accept="setFolder(); clearSelections()"
            acceptButtonText="Save"
            dismissButtonText="Cancel"
            persistent
        >
            <v-form @submit.prevent>
                <fe-label>Folder</fe-label>
                <fe-remote-combo
                    v-if="selectFolderDialog.show"
                    rootProperty="folders"
                    :items="formFolderItems"
                    v-model="selectFolderDialog.record"
                />
            </v-form>
        </fe-dialog>

        <copy-form-dialog :records="copyRecords" @close="copyRecords=null" @reload="onReload" />
        <set-status-dialog :records="setStatusRecords" @close="setStatusRecords=null" @changed="loadData(false)" />
        <rename-dialog :record="renameRecord" @close="renameRecord=null" @changed="loadData(false)" />

        <fe-crud
            :config="intervention ? $models.interventionFormInstance : $models.formInstance"
            @read="v => items = v"
            ref="crud"
            :autoload="false"
        />

        <fe-grid
            :rowData="filteredRows"
            :style="{height: params.dashboard_id ? 'calc(100% - 100px)' : 'calc(100vh - 300px)'}"
            :columnDefs="headers"
            :showAddRowBtn="false"
            :showFullscreen="false"
            :showSaveAsDefault="false"
            :gridOptionOverrides="gridOptions"
            :showToolbar="showToolbar"
            :emptyState="{
                text: emptyStateMessage,
                imageSrc: '/images/empty-state-horn.png',
                imageStyle: 'max-width:50%; max-height:50%;',
            }"
            displayDensity="medium"
            @selectionChanged="doSelection"
            @rowDoubleClicked="loadItem"
            ref="grid"
            :bordered="false"
            @cellClicked="doCellClick"
            domLayout="normal"
        >
            <template #after-search-toolbar-items v-if="showFilters">
                <form-date-filter
                    class="moveup"
                    v-model="filters.date"
                />
                <fe-filter-btn
                    class="moveup"
                    title="Form Template"
                    :items="templates"
                    itemText="name"
                    itemValue="id"
                    v-model="filters.templates"
                    multiple :closeOnSelect="false"
                    showFirstSelection
                />
                <fe-crud autoload :config="$models.form" @read="templates = $event.sort(formSort)" />
            </template>

            <template #toolbar-items v-if="showToolbar">
                <!-- Create Smart Form -->
                <create-smart-form v-if="createDialog.show" @created="loadData(); $emit('updateGrid')" @dismiss="createDialog.show=false"
                       :studentId="$props.studentId" :collectionDashboardId="collectionDashboardId" :interventionId="interventionId" :interventionPlanId="params.intervention_plan_id"/>
                <create-folder v-if="createFolderDialog.show" @created="loadData(); $emit('updateGrid')" @dismiss="createFolderDialog.show=false"/>

                <!-- Add Menu -->
                <v-menu offset-y v-if="showCreateButton">
                    <template v-slot:activator="{ on }">
                        <fe-btn usage="primary" v-on="on">Create</fe-btn>
                    </template>

                    <v-list>
                        <v-list-item @click="createDialog.show = true">
                            <v-list-item-action>
                                <i class="fas fa-file"/>
                            </v-list-item-action>

                            <v-list-item-content>
                                smartFORM
                            </v-list-item-content>
                        </v-list-item>

                        <v-list-item  v-if="folderCreate" @click="createFolderDialog.show = true">
                            <v-list-item-action>
                                <i class="fas fa-folder"/>
                            </v-list-item-action>

                            <v-list-item-content>
                                Folder
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                </v-menu>

                <v-slide-y-transition>
                    <div v-if="selections.length > 0"  class="selection-toolbar d-flex background-default">
                        <div class="mt-3 ml-5">{{selections.length}} Form{{ selections.length > 1 ? 's' : '' }} Selected</div>
                        <div class="ml-auto">
                            <v-btn text class="white--text" @click="setStatusRecords = selections" data-test="bulk-set-status-btn">
                                Set Status
                            </v-btn>
                            <v-btn text class="white--text" @click="copyRecords = selections" data-test="bulk-copy-btn">
                                Bulk Copy
                            </v-btn>
                            <v-btn text class="white--text" @click="checkLanguage(selections)" data-test="bulk-download-btn">
                                Download Zip File
                            </v-btn>
                            <v-btn
                                v-if="guardianEmailDisplay && smartformFolder"
                                text class="white--text"
                                @click="setupGuardianFormEmail(selections)"
                                data-test="bulk-guardian-email-btn"
                            >
                                Set Up Guardian Emails
                            </v-btn>
                            <v-btn text class="white--text" @click="selectFolderDialog.show = true; selectFolderDialog.record = null" data-test="bulk-move-btn">
                                Move to Folder
                            </v-btn>
                            <v-btn text class="white--text" @click="confirmDestroy" data-test="bulk-delete-btn">
                                Delete
                            </v-btn>
                            <v-btn text class="white--text" @click="$refs.grid.gridApi.deselectAll()" data-test="cancel-btn">
                                Cancel
                            </v-btn>
                        </div>
                    </div>
                </v-slide-y-transition>
            </template>

            <template #cellDialog="{cell}">
                <v-list>
                    <v-list-item @click="renameRecord = cell.data" :disabled="canDoRename" data-test="rename-btn">Rename</v-list-item>
                    <v-list-item @click="copyRecords = [cell.data]" data-test="copy-btn">Copy</v-list-item>
                    <v-list-item @click="checkLanguage([cell.data], true)" data-test="print-btn">Print</v-list-item>
                    <v-list-item v-if="collection" @click="unpin(cell.data)" data-test="remove-btn">Remove from Collection</v-list-item>
                </v-list>
            </template>
        </fe-grid>

        <fe-crud ref="permissionsCrud" :config="permissionConfig" autoload  @read="v => userPermissions = v" />

        <fe-dialog
            width="500"
            v-if="homeLanguage.show"
            title="smartFORMS Download Language"
            @dismiss="clearLanguage()"
            @close="clearLanguage()"
            @accept="clearSelections(); clearLanguage(); doDownload();"
            acceptButtonText="Download"
            dismissButtonText="Cancel"
            persistent
        >
            <div>
                <v-select
                    flat solo dense
                    :items="languages"
                    itemText="name"
                    itemValue="id"
                    class="mt-2"
                    v-model="downloadLanguage"
                />
                <div v-if="downloadLanguage == 1 && hasNotMappedLanguage">
                    You are downloading forms with Home Languages that have not been configured for translations.
                    These forms will download to English until the language<span v-if="notMappedLanguages.length > 1">s</span>
                    <span v-for="(lang, index) in notMappedLanguages">
                        <span style="font-weight: bold;"> {{ lang }}</span><span v-if="notMappedLanguages.length > 1 && index !== notMappedLanguages.length - 1">, </span>
                    </span>
                    <span v-if="notMappedLanguages.length > 1"> have</span><span v-else> has</span> been setup in System Configurations.
                </div>
            </div>
        </fe-dialog>
    </div>
</template>

<script>
    import Vuex from 'vuex'
    import { mapState } from 'vuex'
    import SmartFormMixin from './smartFormMixin'
    import SmartForm from './SmartForm'
    import Breadcrumb from '@/components/common/Breadcrumb'
    import CreateSmartForm from './components/CreateForm'
    import CreateFolder from './components/CreateFolder'
    import FormDateFilter from './components/FormDateFilter'
    import CopyFormDialog from './components/dialogs/CopyFormDialog'
    import SetStatusDialog from './components/dialogs/SetStatusDialog'
    import RenameDialog from './components/dialogs/RenameDialog'

    export default {
        name: 'SmartFormsList',
        mixins: [ SmartFormMixin ],

        components: {
            SmartForm,
            Breadcrumb,
            CreateSmartForm,
            CreateFolder,
            FormDateFilter,
            CopyFormDialog,
            SetStatusDialog,
            RenameDialog,
        },
        props: {
            schoolYearId: {
                type: Number,
                default: null
            },
            showToolbar: {
                type: Boolean,
                default: true
            },
            showFilters: {
                type: Boolean,
                default: false
            },
            folderCreate: {
                type: Boolean,
                default: true
            },
            showCreateButton: {
                type: Boolean,
                default: true
            },
            view: {
                type: String,
                default: 'mine'
            },
            params: {
                type: Object,
                default: () => ({})
            },
            orderedIds: {
                type: Array,
                required: false
            },
            title: {
                type: String
            },
            studentId: {
                required: false
            },
            intervention: {
                type: Boolean,
                default: false
            },
            byStudent: {
                type: Boolean,
                default: false
            },
            collection: {
                type: Boolean,
                default: false
            },
            interventionId: {

            },
            smartformFolder: {
                type: Boolean,
                default: false
            },
        },
        computed: {
            ...mapState('global', ['sessionUser', 'uiSavedSearch', 'pings']),
            gridOptions: me => ({
                immutableData: true,
                getRowNodeId: v => {
                    return `i${v.form_instance_id}f${v.folder_id||''}s${v.student_id||''}`
                },
                suppressNoRowsOverlay: true,
            }),
            filteredRows() {
                let formFilterIds = this.filters.templates.included.map(x => x.id)
                let items = this.items.filter(r => {
                    return !r.is_folder
                        && (this.schoolYearId ? r.school_year_id == this.schoolYearId : true)
                        && (formFilterIds.length ? formFilterIds.includes(r.form_id) : true)
                })

                // The "recent smartforms" list will provide a list of form instance ids
                // in the preferred sort order.  If it exists, we defer to that order for sort
                if (this.orderedIds) {
                    // Use soft == cause the backend stores recent_smart_forms int values as strings
                    items = items.sort((a, b) => this.orderedIds.findIndex(itm => itm == b.form_instance_id) - this.orderedIds.findIndex(itm => itm == a.form_instance_id))
                }

                // remove any forms from student form list that the user does not have access to view
                let view = []
                if(Object.keys(this.userPermissions).length) {
                    Object.keys(this.userPermissions[this.sessionUser.user.id]).forEach(key => {
                        if(this.userPermissions[this.sessionUser.user.id][key].can_view) view.push(parseInt(key))
                    })
                    items = items.filter(i => view.includes(i.form_id))
                }

                return items
            },
            loadParams() {
                return this.$props.params
            },
            forms() {
                if (this.editing) return this.items

                return this.items.filter((r) => {
                    return !r.is_folder
                })
            },
            activeTitle() {
                return this.selectedFolder ? this.selectedFolder.folder_name : "My smartFORMS"
            },
            permissionConfig() {
                let cfg = this.$_.cloneDeep(this.$models.formPermissions)
                cfg.read.params.user_id = this.sessionUser.user.id
                return cfg
            },
            formTranslationsEnabled() {
                return this.$store.getters['flags/flags']['e-c-form-translations'] === true
            },
            formFolderItems() {
                if (!this.selectedFolder) {
                    // If user is viewing top level, don't show a "unparent" option
                    return this.allFolders
                } else {
                    return [
                        { id: null, name: "Do not place this in any folder." },
                        ...this.allFolders
                    ]
                }
            },
            guardianEmailDisplay() {
                return this.$store.getters['flags/flags']['ec-guardian-emails'] && this.$hasSecurity('SEND_GUARDIAN_FORM_EMAIL')
            },
        },
        watch: {
            'filters.date': {
                deep: true,
                handler(v) {
                    this.loadData()
                }
            },
            folderId(v) {
                this.selectedForm = null
                this.localFolderId = v
                this.loadData()
            },
            params: {
                handler(v) {
                    this.loadData()
                },
            },
            'selectFolderDialog.show'(v) {
                if (!v) return

                this.$axios.get(this.$models.getUrl('formFolder', 'read'))
                    .then(response => {
                        this.allFolders = response.data.folders
                    })
            },
            'pings.recentSmartForms'() {
                this.loadData()
            },
            'homeLanguage.show'(v) {
                if(v) {
                    this.downloadLanguage = 0
                }
            },
            loading(v) {
                this.$emit('loading', v)
            },
        },
        data() {
            let me = this

            let headers = []

            if (me.$props.showToolbar) {
                headers.push(this.$grid.checkColumn())
            }

            headers.push({
                headerName: 'Form Name',
                field: (me.intervention || me.params.dashboard_id)
                    ? 'form_instance_name'
                    : 'display_name',
                width: 400,
                comparator: me.$grid.sort,
                cellRenderer(params) {
                    let d = params.data
                    let icon = params.data.is_folder ? 'fas fa-folder' : 'far fa-file'

                    if (d.form_instance_locked_flag) {
                        icon = 'far fa-file-pdf'
                    }
                    if (d.form_instance_status_lock_flag) {
                        icon = 'fas fa-lock'
                    }

                    let rtn  = '<i style="margin-right: 10px;" class="' + icon + '"></i>' + params.value
                    if (d.form_instance_status_name) {
                        rtn += '<span style="background: #E0E1E6; border-radius: 12px; margin-left: 8px; font-size: 10px; padding: 4px;">' + d.form_instance_status_name + '</span>'
                    }

                    return rtn
                }
            })

            headers.push({
                headerName: "School Year",
                field: "school_year_name",
                sortable: true
            })

            if(me.byStudent) headers.push({
                headerName: 'Student',
                field: 'student_full_name',
                width: 200,
            })


            headers.push({
                headerName: 'Template',
                width: 300,
                field: 'form_name',
                cellStyle: {
                    color: '#9297A6',
                    fontSize: '12px'
                }
            })

            if (me.showToolbar && !me.intervention && !me.byStudent) {
                headers.push(
                    this.$grid.toggleColumn('Private', 'form_instance_private', null, (v) => {
                        me.$refs.crud.update([v.data])
                            .finally(() => { me.loadData() })
                    }, { colId: 'unsearchable__form_instance_private' }),
                    this.$grid.toggleColumn('Read Only', 'form_instance_locked_flag', null, (v) => {
                        me.$refs.crud.update([v.data])
                            .finally(() => { me.loadData() })
                    }, { colId: 'unsearchable__form_instance_locked_flag' }),
                )
            }

            if(me.byStudent) headers.push({
                headerName: 'Last Updated',
                field: 'form_instance_last_updated',
                maxWidth: 180,
                cellStyle: {
                    color: '#9297A6',
                    fontSize: '12px'
                },
                cellRenderer: v => me.$dayjs(v.value).format('LLL')
            })

            headers.push({
                headerName: 'Created',
                field: this.interventionId ? 'form_instance_created' : 'created',
                maxWidth: 180,
                dateColumnFormat: 'LLL',
                cellStyle: {
                    color: '#9297A6',
                    fontSize: '12px'
                },
                cellRenderer: v => me.$dayjs(v.value).format('LLL')
            }, {
                headerName: 'Owner',
                field: this.intervention ? 'form_created_by_full_name' : 'created_by_full_name',
                hide: this.intervention,
                maxWidth: 180,
                cellStyle: {
                    color: '#9297A6',
                    fontSize: '12px'
                },
                cellRenderer(v) {
                    let val = v.data.created_by_full_name ? v.data.created_by_full_name : v.data.form_instance_created_by_full_name
                    return val
                },
                comparator(valueA, valueB, nodeA, nodeB, isInverted) {
                    // custom sort since field value is dependent on this.intervention value
                    let a = nodeA.data.created_by_full_name ? nodeA.data.created_by_full_name : nodeA.data.form_instance_created_by_full_name
                    let b = nodeB.data.created_by_full_name ? nodeB.data.created_by_full_name : nodeB.data.form_instance_created_by_full_name
                    if (a.toLowerCase() < b.toLowerCase()) {
                        return -1
                    } else if (a.toLowerCase() > b.toLowerCase()){
                        return 1
                    }
                }
            })

            if (me.$props.showToolbar) {
                headers.push({
                    maxWidth: 70,
                    cellRenderer: v => {
                        return '<i class="fe-grid-icon far fa-ellipsis-h theme--light"></i>'
                    },
                    onCellClicked: v => this.$refs.grid.setDialogCell(v),
                    colId: 'actions'
                })
            }

            return {
                localFolderId: null,
                selectedRows: [],
                editing: false,
                selections: [],
                setStatusRecords: null,
                renameRecord: null,
                copyRecords: null,
                selectFolderDialog: {
                    show: false,
                    record: {}
                },
                createDialog: {
                    show: false
                },
                createFolderDialog: {
                    show: false
                },
                headers: headers,
                items: [],
                allFolders: [],
                emptyStateMessage: 'No smartFORMS found',
                collectionDashboardId: null,
                templates: [],
                filters: {
                    date: null,
                    templates: {
                        included: [],
                    },
                },
                userPermissions: {},
                canDoRename: true,
                homeLanguage: {
                    show: false,
                },
                languages: [
                    { id: 0, name: 'English' },
                    { id: 1, name: 'Home Language' },
                ],
                downloadLanguage: 0,
                selected:[],
                hasNotMappedLanguage: false,
                notMappedLanguages: [],
                printing: false,
                loading: false,
            }
        },
        mounted() {
            if (this.$route.query.hideNav) {
                if (this.uiSavedSearch.RECENT_SMART_FORMS) this.$getUiSavedSearch('RECENT_SMART_FORMS')
            }
        },
        methods: {
            unpin(data) {
                this.$emit('unpin', data)
            },
            clearSelections() {
                this.$refs.grid?.gridApi?.deselectAll()
            },
            formSort(a,b) {
                if(a.name.toLowerCase() < b.name.toLowerCase()) return -1
                if(a.name.toLowerCase() > b.name.toLowerCase()) return 1
                return 0
            },
            onShow(e) {
                if(!this.params.folder_id) e.some(x => x.isIntersecting) && setTimeout(this.loadData,10)
            },
            confirmDestroy() {
                let items = this.selections

                this.$confirmDelete(items, () => {
                    let forms = items.filter((rec) => !rec.is_folder)
                    let folders = items.filter((rec) => rec.is_folder)

                    if (forms.length > 0) {
                        this.$refs.crud.destroy(items)
                            .then(response => {})
                            .finally(() => {
                                this.loadData()
                                this.$emit('updateGrid')
                            })
                    }
                })
            },
            go(item) {
                if (item.show === 'index') {
                    this.selectedForm = null
                    this.localFolderId = null
                    this.loadData()
                } else {
                    this.loadItem(item.data)
                }
            },
            setFolder() {
                let r = [...this.selections]

                r.forEach(rec => {
                    rec.parent_folder_id = this.selectFolderDialog.record.id || null // "no folder" is set as id: 0, we'll null it out on the backend
                })

                this.$refs.crud.update(r)
                    .then(response => {})
                    .finally(() => {
                        this.selectFolderDialog.show = false
                        this.loadData()
                    })
            },
            loadItem(row) {
                let me = this
                let rec = row.data
                if (rec.is_folder) {
                    this.$emit('select', row.data)
                    this.$emit('openFolder', row.data)
                } else {
                    this.$emit('openItem', row.data)
                }

                if (rec.form_instance_locked_flag || rec.form_instance_status_lock_flag) {
                    this.print(rec)
                } else {
                    this.$store.commit('global/addDockableWindow', {
                        name: rec.display_name,
                        data: rec,
                        component: 'smart-form',
                        attrs: {
                            id: rec.form_instance_id,
                            template_id: rec.form_id,
                        },
                        events: {
                            close() {
                                me.$emit('closeDialog')
                                me.loadData()
                            },
                            toggle(open) {
                                me.$emit('toggleDialog', open)
                            }
                        }
                    })
                }
            },
            print(rec, downloadLang = null) {
                this.printing = false
                let targetLanguage = []
                if(rec.hasOwnProperty('home_language_code') && downloadLang === 1) {
                    targetLanguage.push(rec.form_instance_id.toString() + ':' + rec.home_language_code.toString())
                }
                let sendTargetLang = ''
                if(this.formTranslationsEnabled && downloadLang === 1) {
                    sendTargetLang = '&target_language=' + targetLanguage
                }
                this.$store.commit('global/addDockableWindow', {
                    name: rec.display_name,
                    component: 'IFrameRenderer',
                    attrs: {
                        app: 'bec',
                        url: this.$axios.defaults.baseURL+'formPrint.php?printformid=' + rec.form_instance_id + sendTargetLang
                    },
                    events: {
                        close: () => this.$emit('closeDialog'),
                        toggle: (open) => this.$emit('toggleDialog', open),
                    }
                })
            },
            doSelection() {
                this.selections = this.$refs.grid.gridApi.getSelectedRows()
            },
            unSelect(keep) {
                this.$refs.grid.gridApi.getSelectedNodes().map(n => {
                    if(!keep.find(rec => rec == n.data)) n.setSelected(false)
                })
            },
            onReload(e) {
                this.loadData(e && e.deferred)
            },
            loadData(keepSelections = false) {
                let grid = document.querySelector('.fe-grid')
                grid.removeAttribute('id')
                if(this.params.folder_id) grid.id = 'folder-grid'
                else grid.id = 'main-grid'
                this.items = []
                let p = {}
                if(!keepSelections) this.clearSelections()

                if (this.$props.studentId) {
                    p.student_id = this.$props.studentId
                    p.property   = 'student_instance'
                } else if (this.$props.params.dashboard_id) {
                    this.collectionDashboardId = this.$props.params.dashboard_id
                    this.$axios.get('dashboard.php?action=get&property=form_instances&dashboard_id=' + this.$props.params.dashboard_id)
                        .then(response => {
                            if(response.data.dashboard_form_instances.length === 0) {
                                this.emptyStateMessage = 'You have not created or linked any smartFORMs to this collection yet. <br/> To get started, click on Create.'
                                this.items = []
                            } else {
                                this.items = response.data.dashboard_form_instances
                            }
                        })
                    return
                } else if (this.$props.params) {
                    // if coming from interventions and params undefined, return so there's no error
                    if (this.intervention && Object.values(this.$props.params).every(x => x == undefined)) {
                        return
                    }
                    p = {
                        ...this.params,
                        ...this.filters.date,
                    }
                } else {
                    console.warn('Invalid properties for form list')
                    return
                }

                if (!this.$_.isBlank(p) && this.$refs.crud) {
                    this.loading = true
                    this.$refs.crud.read(p).then(r => {
                        this.$emit('loaded', r.data.form_instances)
                        this.$refs.grid.gridApi.redrawRows()
                    }).finally(() => this.loading = false)
                }

            },
            async hasCustomFormName(v) {
                let resp = await this.$axios.get('form.php?action=get&property=name_config_status&form_id=' + v.form_id)
                this.canDoRename = resp.data.status ? resp.data.status === 1 : false
            },
            doCellClick(v) {
                // can't call promise from :disabled
                // so get value of status on click of ... button
                // and set if user can rename
                if(v.column.colId === 'actions') {
                    this.hasCustomFormName(v.data)
                }
            },
            async checkLanguage(selections, print = false) {
                if(print) this.printing = true
                // IF NOT A STUDENT FORM
                // don't go through the rest of the code
                if(!selections.every(x => x.student_id)) {
                    this.downloadLanguage = 0
                    if(!print) this.doDownload(selections)
                    else this.print(selections[0])
                }
                else {
                    this.hasNotMappedLanguage = false
                    this.notMappedLanguages = []
                    // if form translations not enabled, go directly to download
                    if(!this.formTranslationsEnabled) {
                        this.downloadLanguage = 0
                        if(!print) this.doDownload(selections)
                        else this.print(selections[0])
                    } else {
                        // grab list of home languages to compare student's home language id
                        let languagesList = await new Promise((resolve, reject) => {
                            this.$modelGet('homeLanguage')
                                .then((response) => {
                                    resolve(response)
                                })
                        })
                        // only check home languages if mapping of aws codes is done for at least one language
                        if(!languagesList.every(x => x.language_code_id == null)) {
                            // check to see if any of the students selected have home language set
                            this.$axios.get('students.php?action=get&property=student&id=' + selections.map(x => x.student_id).join(','))
                                .then((resp) => {
                                    if(resp.data.some(x => x.home_language_id)) {
                                        // find single student's home language
                                        // if english, don't show modal
                                        // else show modal and update list to show specific home language
                                        if(selections.length === 1) {
                                            let display = languagesList.find(x => x.id === resp.data[0].home_language_id).home_language_display
                                            let found = languagesList.find(x => x.id === resp.data[0].home_language_id)
                                            let code = 18
                                            if(found) {
                                                if (found.language_code_id) {
                                                    code = languagesList.find(x => x.id === resp.data[0].home_language_id).language_code_id
                                                } else {
                                                    this.downloadLanguage = 0
                                                    this.hasNotMappedLanguage = true
                                                    this.notMappedLanguages = [found.home_language_display]
                                                }
                                            }
                                            if(display.toLowerCase() !== 'english' && display.toLowerCase() !== 'eng') {
                                                this.homeLanguage.show = true
                                                this.languages[1].name += ' (' + display + ')'
                                                selections[0].home_language_code = code
                                                this.selected = selections
                                            } else {
                                                this.downloadLanguage = 0
                                                if (!print) this.doDownload(selections)
                                                else this.print(selections[0])
                                            }
                                        } else {
                                            // grab array of home language ids from all students, ignoring nulls
                                            let langs = resp.data.map(x => x.home_language_id).filter(Boolean)
                                            // grab english id, and only show modal if at least one student has
                                            // a home language that's not english
                                            let englishFound = languagesList.find(x => x.home_language_display.toLowerCase() === 'english' || x.home_language_display.toLowerCase() === 'eng' )
                                            let english = englishFound ? englishFound.id : null
                                            if(langs.some(x => x !== english)) {
                                                this.homeLanguage.show = true
                                                selections.forEach(sel => {
                                                    let hlc = 18
                                                    let found = languagesList.find(y => y.id === resp.data.find(x => x.id === sel.student_id)?.home_language_id)
                                                    if(found) {
                                                        if(found.language_code_id) {
                                                            hlc = languagesList.find(y => y.id === resp.data.find(x => x.id === sel.student_id).home_language_id).language_code_id
                                                        } else {
                                                            this.hasNotMappedLanguage = true
                                                            this.notMappedLanguages.push(found.home_language_display)
                                                        }
                                                    }
                                                    sel.home_language_code = hlc
                                                })
                                                this.selected = selections
                                            } else {
                                                this.downloadLanguage = 0
                                                if(!print) this.doDownload(selections)
                                                else this.print(selections[0])
                                            }
                                        }
                                    } else {
                                        this.downloadLanguage = 0
                                        if(!print) this.doDownload(selections)
                                        else this.print(selections[0])
                                    }
                                })
                        } else {
                            this.downloadLanguage = 0
                            if(!print) this.doDownload(selections)
                            else this.print(selections[0])
                        }
                    }
                }
            },
            doDownload(selections = []) {
                if(this.printing) {
                    this.print(selections.length ? selections[0] : this.selected[0], this.downloadLanguage)
                    this.printing = false
                } else {
                    this.unSelect(this.downloadFormZip(selections.length ? selections : this.selected, this.downloadLanguage))
                }
            },
            clearLanguage() {
                this.homeLanguage.show = false
                this.languages[1].name = 'Home Language'
            },
            setupGuardianFormEmail(selections) {
                const formIds = selections.map(selection => selection.form_instance_id)
                this.$router.push({
                    name: 'Guardian Email Setup',
                    query: {
                        forms: formIds.join(','), 
                        selections: JSON.stringify(selections)
                    }
                })
            },
        }
    }
</script>

<style lang="scss" scoped>
.moveup ::v-deep button.v-btn {
    margin-top: 0;
}

.selection-toolbar {
    width: 100%;
    position: absolute;
    left: 0px;
    height: 48px;
    color: white;
    z-index: 10;
    border-radius: 4px;
}

.background-default {
    background: #66A7BC;
}

.form-list-title {
    padding-top: 7px;
}

.form_status_chip {
    background: #E0E1E6;
    border-radius: 12px;
}

.empty-overlay {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-top: 100px;
    width: 100%;
    height: 400px;
    z-index: 1;
    // &-small-screen {
    //     width: calc(100% - 200px);
    // }
}
</style>
