<template>
    <div class="card mx-2">
        <div class="d-flex upper">
            <div class="flex-grow-1 pt-2">
                Current Enrollment & Trends
                <div class="title-tip-adj">
                    <fe-info-tip
                        class="ml-2"
                        color="#7e8494 !important"
                        icon="fas fa-info-circle"
                        tooltip="Enrollment data is provided by your Student Information System (SIS) and syncs with eduCLIMBER each night."
                    />
                </div>
                <div class="sub-title">
                    {{ schoolTitleDisplay }}
                </div>
            </div>

            <v-menu class="flex-shrink-1" offset-y left>
                <template v-slot:activator="{ on }">
                    <v-btn text icon data-test="enrollment-menu-btn">
                        <v-icon v-on="on">more_vert</v-icon>
                    </v-btn>
                </template>

                <slot name="menu">
                    <v-card flat style="background: #fff; padding: 10px">
                        <slot name="extra-menu-items"></slot>
                        <v-btn style="padding: 0" text @click="printChart" data-test="enrollment-print-btn">
                            <v-icon class="mr-2" style="color:rgba(0, 0, 0, 0.54)">fa-print</v-icon>
                            Print PDF
                        </v-btn>
                        <br/>
                        <v-btn style="padding: 0" text @click="exportChart" data-test="enrollment-download-btn">
                            <v-icon class="mr-2" style="color:rgba(0, 0, 0, 0.54)">fa-download</v-icon>
                            Download Image
                        </v-btn>
                    </v-card>
                </slot>
            </v-menu>
        </div>

        <div v-if="params" id="enrollment-data-viz" class="lower pt-3">
            <div class="d-flex details flex-grow-1">
                <div class="total">
                    <div class="total-num" data-test="enrollment-yesterday-num" @click="$emit('click')" style="cursor: pointer;">{{ totalEnrDisplay }}</div>
                    <div class="total-text">yesterday's enrollment</div>
                </div>

                <v-spacer/>

                <div class="delta">
                    <div v-if="fromLastYear.num != 0" class="d-flex row">
                        <div class="value" data-test="enrollment-last-year-num" @click="$emit('click')" :class="[fromLastYear.delta]">
                            <v-icon class="caret">{{ `fa-caret-${fromLastYear.delta}` }}</v-icon>
                            {{ fromLastYear.num }}
                        </div>
                        <div class="flex-grow-1 category">
                            from this time last year
                        </div>
                    </div>
                    <div v-else class="d-flex row">
                        <div class="value" data-test="enrollment-last-year-num" @click="$emit('click')">
                            <div >{{ fromLastYear.num }}</div>
                        </div>
                        <div class="flex-grow-1 category">
                            no change from last year
                        </div>
                    </div>

                    <div v-if="fromLastMonth.num != 0" class="d-flex row">
                        <div class="value" data-test="enrollment-last-month-num" @click="$emit('click')" :class="[fromLastMonth.delta]">
                            <v-icon class="caret">{{ `fa-caret-${fromLastMonth.delta}` }}</v-icon>
                            {{ fromLastMonth.num }}
                        </div>
                        <div class="flex-grow-1 category" style="width: 130px;">
                            from this time last month
                        </div>
                    </div>
                    <div v-else class="d-flex row">
                        <div class="value" data-test="enrollment-last-month-num" @click="$emit('click')">
                            <div>{{ fromLastMonth.num }}</div>
                        </div>
                        <div class="flex-grow-1 category">
                           no change from last month
                        </div>
                    </div>
                </div>
            </div>

            <highcharts
                ref="enrollmentChart"
                class="chart"
                :options="chartOptions"
            />

            <fe-btn
                @click="$emit('click')"
                usage="secondary"
                style="float: right;"
                data-test="enrollment-explore-btn"
            >
                Explore
            </fe-btn>
        </div>
    </div>
</template>

<script>
import {mapState} from "vuex";
import * as ChartExportUtil from '../common/util/ChartExports'
import html2canvas from 'html2canvas';
import Printd from 'printd';

export default {
    name: 'EnrollmentCard',

    props: {
        params: {
            type: Object,
            default: {}
        }
    },

    data() {
        return {
            schoolTitleDisplay: ''
        }
    },

    computed: {
        ...mapState('global', ['currentYear', 'defaultSchool']),

        totalEnrDisplay() {
            return new Intl.NumberFormat().format(this.params?.total_enrolled)
        },

        fromLastYear() {
            return this.display(this.params.from_this_time_last_year_enrolled)
        },

        fromLastMonth() {
            return this.display(this.params.from_last_month_enrolled)
        },

        schoolUrl() {
            return 'schools.php?action=get&school_year_id=' + this.currentYear.id
        },

        chartOptions() {
            let categories = this.params?.charts.map(x => {
                let y = x.school_year
                return y.slice(0,5) + y.slice(7)
            })

            let me = this
            let series = [{
                name: 'Enrolled Students',
                data: this.params?.charts.map(x => x.student_count),
                color: '#006c90',
                cursor: 'pointer',
                point: {
                    events: {
                        click: function (event) {
                            me.$emit('click')
                        }
                    }
                }
            }]

            return {
                chart: {
                    events: {
                        load: function() {
                            const series = this.series[0]
                            series.group.element.setAttribute('data-test', 'enrollment-summary-line')
                        }
                    },
                    height: '170px'
                },
                credits: {
                    enabled: false
                },
                navigation: {
                    buttonOptions: {
                        enabled: false
                    }
                },
                title: {
                    text: '',
                },
                xAxis: {
                    categories,
                    title: {
                        text: undefined
                    },
                },
                yAxis: {
                    title: {
                        text: undefined
                    },
                    labels: {
                        formatter: x => new Intl.NumberFormat().format(x.value)
                    }
                },
                legend: {
                    enabled: false
                },
                plotOptions: {
                    line: {
                        marker: {
                            enabled: false
                        }
                    }
                },
                series
            }
        }
    },

    mounted() {
        this.schoolDisplay()
    },

    methods: {
        display(value) {
            let delta = value < 0 ? 'down' : 'up'

            return {
                num: new Intl.NumberFormat().format(Math.abs(value)),
                delta,
            }
        },

        printChart() {
            html2canvas(document.querySelector('#enrollment-data-viz')).then(canvas => {
                const imgURL = canvas.toDataURL('image/jpeg')
                const image = document.createElement('img')
                image.src = imgURL
                image.setAttribute("id", 'print-content')
                document.body.appendChild(image)
                const printD = new Printd();
                printD.print(document.getElementById('print-content'))
                document.body.removeChild(image)
            })
        },

        exportChart() {
            let enrollmentDiv = document.querySelector('#enrollment-data-viz')
            enrollmentDiv.classList.add('download-padding')

            html2canvas(enrollmentDiv).then(canvas => {
                const link = document.createElement('a')
                link.setAttribute('download', 'Enrollment-Summary.png')
                link.setAttribute('href', canvas.toDataURL('image/png').replace('image/png', 'image/octet-stream'))
                link.click()
            })
            setTimeout(() => {enrollmentDiv.classList.remove('download-padding')}, 20)
        },

        schoolDisplay() {
            if (this.defaultSchool) {
                this.schoolTitleDisplay = this.defaultSchool.name
            } else {
                Promise.resolve(
                    this.$axios.get(this.schoolUrl)
                ).then((response) => {
                    if (response && response.data) {
                        let schools = response.data.schools.map(school => school.name ? school.name : school.school_year_name)
                        if (schools.length === 1) {
                            this.schoolTitleDisplay = schools[0] ? schools[0] : schools
                        } else {
                            this.schoolTitleDisplay = `${schools.length} schools`
                        }
                    }
                })
            }

        },
    }
}
</script>

<style scoped lang="scss">
.card {

    .upper {
        height: 60px;
        border-bottom: 1px solid #F3F3F3;
        padding-top: 4px;
    }

    .lower {

        .details {
            height: 70px;

            .total {
                min-width: 90px;
                color: var(--v-primary-base);
                font-size: 24px;
                line-height: 31px;
                text-align: left;
            }

            .total-num {
                font-weight: 600;
            }

            .total-text {
                text-align: left;
                font-size: 14px;
                font-weight: unset;
                color: #828795;
            }

            .delta {
                width: 240px;
                color: var(--v-primary-base);

                .row {
                    height: 25px;

                    .value {
                        text-align: right;
                        font-size: 16px;
                        font-weight: 600;
                        line-height: 20px;
                        padding-right: 8px;
                        width: 86px;
                        cursor: pointer;

                        .caret {
                            font-size: 20px;
                            color: inherit !important;
                        }
                    }

                    .category {
                        text-align: left;
                        font-size: 12px;
                        color: #828795;
                    }
                }
            }
        }

        .chart {
            height: 170px;
        }
    }
}

.download-padding {
    padding-left: 5px;
}

.title-tip-adj {
    display: inline-block;
    position: relative;
    top: -3px;
}

.sub-title {
    text-align: left;
    font-size: 12px;
    color: #828795;
}
</style>
