
export function printChartById(divId) {
    let contents = document.getElementById(divId).innerHTML
    let html= '<head><style>@media print { html, body { height: auto; margin: 0 !important; padding: 0 !important; } }</style></head><body>'+contents+'</body>'

    let frame1 = document.createElement('iframe')
    let frameName = 'frame1'

    frame1.name = frameName
    document.body.appendChild(frame1)

    let frameDoc = frame1.contentWindow

    frameDoc.document.open();
    frameDoc.document.write(html);
    frameDoc.document.close();

    window.frames[frameName].focus();
    window.frames[frameName].print();

    document.body.removeChild(frame1);
}

export function exportChart(chart, title= 'Chart') {
    chart.exportChart({
        type: 'image/png',
        filename: title
    })
}
