<template>
    <div class="d-flex flex-fill flex-column no-scroll">
        <div data-test="intervention-filter-bar" class="d-flex" style="border-bottom: 1px solid #dddddd;">
            <fe-filter-btn
                title="Year"
                url="qry.php?property=school_years"
                rootProperty="years"
                itemValue="id"
                itemText="name"
                :multiple="false"
                v-model="selectedSchoolYear"
                @input="loadPlans"
            />

            <fe-filter-btn
                v-model="selectedUser"
                title="Interventionist"
                url="users.php?action=get&active=1&detail=0"
                itemValue="id"
                itemText="user_full_name"
                showClearButton
                :multiple="false"
                :showApplyButton="false"
                :disabled="!$hasSecurity('SEARCH_BY_INTERVENTIONIST')"
                buffered
                @input="loadPlans"
            />

            <fe-filter-btn
                v-model="selectedPlan"
                ref="planSelector"
                itemValue="id"
                itemText="name"
                :multiple="false"
                :title="plans.length > 0 ? 'Group' : 'No Groups Available'"
                :items="plans"
                :showApplyButton="false"
                :disabled="plans.length === 0"
                showClearButton
            >
                <template #subtext="{item}">
                    <div :style="new Date(item.end_date) < new Date() ? 'color: #7E8494' : ''">
                        {{ item.name }}

                        <div style="font-size: 10px">{{ item.start_date }} to {{ item.end_date }}</div>
                    </div>
                </template>
            </fe-filter-btn>
        </div>

        <!-- Modify / Change Phase -->
        <new-phase
            v-if="phaseDialog.show"
            v-model="phaseDialog.show"
            :interventionPlanId="currentPlan.id"
            :defaultStudent="currentStudent"
            @new-phase="submitNewPhase"
        />

        <div data-test="intervention-left-nav" class="d-flex flex-fill" style="overflow: auto;" v-if="currentPlan">
            <div class="student-list d-flex flex-fill flex-column flex-shrink-0 flex-grow-0">
                <div
                    class="group-title d-flex"
                    v-if="currentPlan"
                    @click="groupView=true"
                    :class="{'student-row-selected': groupView}"
                >
                    <div class="group-title-icon">
                        <v-icon color="white">fas fa-users</v-icon>
                    </div>

                    <div class="group-title-name">
                        {{ currentPlan.name }}
                        <span v-if="studentCount" class="ml-1">
                            {{ studentCount }}
                            <fe-tooltip
                                direction="top"
                                class="d-inline-block ml-1"
                                tooltip="Some students in this intervention are not displayed based on your current permissions."
                            >
                                <v-icon style="font-size:14px; color:black;">fal fa-exclamation-circle</v-icon>
                            </fe-tooltip>
                        </span>
                    </div>

                    <v-spacer/>

                    <div class="mr-1 mt-1 group-title-tool">
                        <menu-btn>
                            <v-list-item v-if="currentPlan.category_id !== 4" @click="addStudentDialog.show=true">
                                Add Student
                            </v-list-item>

                            <v-list-item @click="startNewPhase">
                                New Phase
                            </v-list-item>

                            <v-list-item v-if="currentPlan.category_id !== 4" @click="addMonitor">
                                Add Measure
                            </v-list-item>

                            <v-list-item @click="confirmDeletePlan('delete_confirm')">
                                Delete
                            </v-list-item>
                        </menu-btn>
                    </div>
                    <!--  -->

                    <!-- </div> -->
                </div>

                <div data-test="intervention-left-nav-students" class="d-flex flex-column" style="overflow-y: auto;">
                    <div
                        v-for="(s,i) in students"
                        data-test="intervention-left-nav-student"
                        :key="`student-`+i"
                        class="d-flex student-row"
                        :class="{'student-row-selected': currentStudent && s.student_id === currentStudent.student_id && !groupView}"
                        @click="loadStudent(s)"
                    >
                        <div class="student-avatar">
                            <v-avatar size="28px">
                                <v-img :src="getStudentImg(s.student_id)"/>
                            </v-avatar>
                        </div>

                        <div class="student-name" @mouseenter="giveTitleIfTruncated($event)">
                            {{ s.student_full_name }}
                        </div>

                        <div class="student-on-track">
                            <template v-if="s.category_id == 4 && s.primary_probe">
                                <v-icon
                                    v-if="s.category_id == 4 && (s.primary_probe.four_week_possible_pct < 80 || s.primary_probe.goal > s.primary_probe.four_week_possible_pct)"
                                    color="warning"
                                >
                                    fas fa-times
                                </v-icon>

                                <v-icon v-else color="success">fas fa-check</v-icon>
                            </template>

                            <template v-else>
                                <v-icon v-if="s.primary_probe && s.primary_probe.on_track" color="success">
                                    fas fa-check
                                </v-icon>

                                <v-icon v-else-if="s.primary_probe && s.primary_probe.not_on_track" color="warning">
                                    fas fa-times
                                </v-icon>

                                <v-icon v-else-if="s.primary_probe && s.primary_probe.no_roi">
                                    fas fa-question-circle
                                </v-icon>

                                <v-icon v-else>fas fa-exclamation-circle</v-icon>
                            </template>
                        </div>

                        <div class="student-fidelity flex-grow-1" :style="{color: getFidelityColor(s.fidelity)}">
                            {{ s.fidelity ? s.fidelity + '%' : '--% ' }}
                        </div>

                    </div>
                </div>
            </div>

            <div
                v-if="!groupView"
                class="main-container d-flex flex-fill flex-column flex-grow-1"
                style="min-width: 850px;"
            >
                <div class="d-flex pt-2 pl-3">
                    <v-avatar size="36px" v-if="currentStudent">
                        <v-img :src="getStudentImg(currentStudent.student_id)"/>
                    </v-avatar>

                    <div class="pl-2 fs24">
                        {{ currentStudent ? currentStudent.student_full_name : 'Select Student' }}
                    </div>
                    <v-spacer/>

                    <menu-btn class="mr-5" v-if="currentPlan && currentPlan.category_id !== 4">
                        <!-- Academic intervention -->
                        <template v-if="currentPlan.category_id !== 4">
                            <v-list-item @click="startNewPhase(currentStudent)">
                                New Phase
                            </v-list-item>

                            <v-list-item @click="addMonitor" v-if="currentPlan.category_id !== 4">
                                Add Measure
                            </v-list-item>
                        </template>

                        <!-- Behavioral intervention -->
                        <template v-else>
                            <!-- Reserved for future use -->
                        </template>
                    </menu-btn>
                </div>

                <div style="border-bottom: 1px solid #dddddd;">
                    <v-tabs height="28px" v-model="activeTab" class="ec-collection-tabs">
                        <v-tab :disabled="!currentStudent" class="small-tab small-tab-all">Plan History</v-tab>
                        <v-tab :disabled="!currentStudent" class="small-tab small-tab-all">Progress Monitoring</v-tab>
                        <v-tab :disabled="!currentStudent" class="small-tab small-tab-all">Comments</v-tab>
                        <v-tab :disabled="!currentStudent" class="small-tab small-tab-all">Reports</v-tab>
                    </v-tabs>
                </div>

                <v-carousel
                    v-model="activeTab"
                    height="100%"
                    hide-delimiters light
                    :show-arrows="false"
                >
                    <v-carousel-item key="0">
                        <plan-history
                            v-if="currentStudent && activeTab === 0"
                            :params="messageParams" :key="`student-plan-`+currentStudent.student_id"
                            @monitor-click="activeTab=1"
                            @message-click="activeTab=2"
                            @loaded-student="reloadPlanHistory ? loadGroup(currentPlan, true) : null; reloadPlanHistory=false"
                            :reload="reloadPlanHistory"
                            @setInterventionId="setInterventionId"
                            :currentPlan="currentPlan"
                        />
                    </v-carousel-item>

                    <v-carousel-item key="1" style="margin: 16px 16px 16px 16px; border: 1px solid #dddddd; border-radius: 4px;">
                        <div v-if="currentPlan.category_id !== 4" class="d-flex flex-fill flex-column" style="overflow-y: scroll;">
                            <template v-if="currentStudent">
                                <academic-progress-monitoring
                                    :ref="`academic-monitor-${c.student_monitor_plan_id}`"
                                    :key="`academic-monitor-${ci}`"
                                    v-for="(c,ci) in chartData"
                                    :chartData="[c]"
                                    :currentMonitors="currentMonitors"
                                    show-score-table
                                    @delete="loadMonitors()"
                                    @reload="onReloadProgressMonitoring"
                                    :selectedSchoolYear="selectedSchoolYear"
                                    @showAllYearsClicked="showAllYrsForMonitor"
                                />
                            </template>
                        </div>

                        <div v-else class="d-flex flex-fill flex-column" style="overflow: scroll;">
                            <behavior-progress-monitoring
                                v-if="currentStudent"
                                :params="{intervention_plan_id: currentStudent.intervention_plan_id, student_monitor_plan_id: currentStudent.student_monitor_plan_id, student_id: currentStudent.student_id }"
                                includeSummary
                            />
                        </div>
                    </v-carousel-item>

                    <v-carousel-item key="2">
                        <message
                            v-if="activeTab === 2 && currentStudent"
                            :key="`messagetab${currentStudent.student_id}`"
                            style="margin: 16px;"
                            :params="messageParams"
                        />
                    </v-carousel-item>

                    <v-carousel-item key="3">
                        <div class="d-flex">
                            <fe-dialog
                                v-if="printing"
                                width="80%"
                                title="Print Summary"
                                @close="printing = false"
                                @cancel="printing = false"
                            >
                                <iframe style="height: 60vh;" class="pdf-iframe" :src="pdfUrl"/>
                            </fe-dialog>

                            <div class="pdf-container flex-grow-1" @click="creationReport">
                                <v-icon class="mx-4">far fa-file-pdf</v-icon>
                                Creation Report
                            </div>

                            <div class="pdf-container flex-grow-1" @click="fidelityReport">
                                <v-icon class="mx-4">far fa-file-pdf</v-icon>
                                Fidelity Report
                            </div>
                        </div>

                        <div class="d-flex flex-fill flex-column" style="padding-bottom: 70px;">
                            <list
                                v-if="activeTab == 3 && currentStudent"
                                style="margin: 16px;"
                                :params="formsParams"
                                :showToolbar="false"
                                intervention
                            />
                        </div>
                    </v-carousel-item>
                </v-carousel>
            </div>

            <group-view
                v-if="groupView"
                :name="currentPlan.name"
                :interventionPlanId="currentPlan.id"
                :interventionId="interventionId"
                @reload="loadPlans"
                @new-phase="startNewPhase"
                @setInterventionId="setInterventionId"
            />
        </div>

        <fe-overlay
            v-else
            :value="!currentPlan"
            image="/images/empty-state-horn.png"
            header="Intervention Workspace"
            text="Select a group from the filter above"
            color="white"
            :absolute="false"
        />

        <add-academic-monitor
            v-if="monitorDialog.show"
            :students="monitorDialog.students"
            :monitors="monitorDialog.monitors"
            @dismiss="monitorDialog.show=false"
            @save="monitorDialog.show=false; loadInterventionPlan()"
        />

        <fe-dialog
            v-if="addStudentDialog.show"
            title="Add Student"
            width="500px"
            persistent
            dismissButtonText="Cancel"
            acceptButtonText="Add"
            :acceptButtonDisabled="!addStudentDialog.student || !addStudentDialog.date"
            @close="addStudentDialog.show=false"
            @accept="addStudents"
        >
            <v-form v-model="addStudentDialog.valid">
                <fe-label>Effective Date</fe-label>
                <fe-date-picker v-model="addStudentDialog.date" dateFormat="MM/DD/YYYY"/>
                <fe-label>Select Student</fe-label>
                <student-name-search byId v-model="addStudentDialog.student" :schoolYearId="relevantSchoolYearId"/>
            </v-form>
        </fe-dialog>

        <fe-dialog
            v-if="dateChangeDialog.show"
            title="Change Dates"
            dismissButtonText="Cancel"
            acceptButtonText="Save"
            :acceptButtonDisabled="!dateChangeDialog.valid"
            persistent
            @close="dateChangeDialog.show = false"
            @accept="changeDates"
        >
            <v-form ref="dateChangeDialogForm" v-model="dateChangeDialog.valid">
                <fe-remote-combo
                    label="Student"
                    v-model="dateChangeDialogStudent"
                    itemText="student_full_name"
                    itemValue="intervention_id"
                    :rules="$fieldValidators('select', 'Student', { required: true })"
                    :items="students"
                    returnObject
                />

                <div class="d-flex">
                    <fe-date-picker
                        v-model="dateChangeDialog.startDate"
                        label="Start Date"
                        class="flex-grow-1 mr-2"
                        dateFormat="MM/DD/YYYY"
                        :disabled="!dateChangeDialog.student"
                        :rules="$fieldValidators('text', 'Start Date', { required: true })"
                    />

                    <fe-date-picker
                        v-model="dateChangeDialog.endDate"
                        label="End Date"
                        class="flex-grow-1 ml-2"
                        dateFormat="MM/DD/YYYY"
                        :disabled="!dateChangeDialog.student"
                        :rules="$fieldValidators('text', 'End Date', { required: true })"
                    />
                </div>

                <fe-remote-combo
                    v-model="dateChangeDialog.exitStatus"
                    label="Exit Status (optional)"
                    url="/crud.php?property=InterventionExitStatus&action=get"
                    rootProperty="intervention_exit_status"
                    itemText="name"
                    itemValue="id"
                    by-id
                />
            </v-form>
        </fe-dialog>

        <fe-crud
            autoload
            ref="crudYears"
            :config="$models.schoolYear"
            @read="availableYears = $event"
        />
    </div>
</template>

<script>
import {mapState} from 'vuex'
import AcademicProgressMonitoring from '@/components/charts/AcademicProgressMonitoring'
import BehaviorProgressMonitoring from '@/components/charts/BehaviorProgressMonitoring'
import Message from '@/components/common/Message'
import List from '@/components/modules/smartforms/List'
import PlanHistory from './PlanHistory'
import GroupView from './GroupView'
import MenuBtn from '@/components/common/button/MenuBtn'
import StudentNameSearch from '@/components/common/StudentNameSearch'
import NewPhase from './NewPhase'
import AddAcademicMonitor from '@/components/modules/intervention/monitor/AddAcademicMonitor'

export default {
    name: 'InterventionAnalysis',

    components: {
        AcademicProgressMonitoring,
        Message,
        List,
        PlanHistory,
        GroupView,
        MenuBtn,
        StudentNameSearch,
        NewPhase,
        BehaviorProgressMonitoring,
        AddAcademicMonitor
    },

    props: {
        interventionPlanId: {},
        studentId: {},
        interventionYear: {}
    },

    data() {
        return {
            monitorDialog: {
                show: false,
                students: [],
                monitors: []
            },
            dateChangeDialog: {
                show: false,
                valid: true,
                student: null,
                startDate: null,
                endDate: null,
                exitStatus: null
            },
            availableYears: [],
            phaseDialog: {
                show: false
            },
            addStudentDialog: {
                show: false,
                student: null,
                date: null
            },
            addingStudents: [],
            printing: false,
            pdfUrl: '',
            selectedSchoolYear: {
                included: []
            },
            selectedPlan: {
                included: []
            },
            plans: [],
            students: [],
            currentPlan: null,
            currentStudent: null,
            currentMonitors: null,
            activeTab: null,
            chartData: [],
            groupView: false,
            reloadPlanHistory: false,
            interventionId: null,
            studentInterventionId: null,
            dockableWindowId: null,
            showAllYears: false,
        }
    },

    computed: {
        ...mapState('global', ['sessionUser', 'currentYear']),
        dateChangeDialogStudent: {
            get() {
                this.dateChangeDialog.student
            },
            set(v) {
                this.dateChangeDialog.student = v
            }
        },
        studentCount() {
            if (this.students?.length && this.students[0].group_student_count
                && (this.students.length != this.students[0].group_student_count)
            ) {
                return `(${this.students.length}/${this.students[0].group_student_count})`
            }

            if (this.students?.length && this.students[0].visible_students === 0) {
                return `(${this.students[0].visible_students}/${this.students[0].group_student_count})`
            }
        },
        messageParams() {
            return {
                student_id: this.currentStudent.student_id,
                intervention_plan_id: this.currentStudent.intervention_plan_id
            }
        },
        formsParams() {
            return {
                student_id: this.currentStudent.student_id,
                intervention_plan_id: this.currentStudent.intervention_plan_id
            }
        },
        relevantSchoolYearId() {
            if (this.addStudentDialog.date) {
                let selected = this.$dayjs.utc(this.addStudentDialog.date, ['MM/DD/YYYY', 'YYYY-MM-DD'])
                let relevantYear = this.availableYears.find(yr => {
                    return this.$dayjs.utc(yr.year_start, "YYYY-MM-DD") <= selected &&
                        this.$dayjs.utc(yr.year_end, "YYYY-MM-DD") >= selected
                })
                return relevantYear ? relevantYear.id : null
            }
            return null
        }
    },

    watch: {
        'dateChangeDialog.show'(v) {
            if (v) {
                this.dateChangeDialog.valid = true
                this.dateChangeDialog.student = null
                this.dateChangeDialog.startDate = null
                this.dateChangeDialog.endDate = null
                this.dateChangeDialog.exitStatus = null
            }
        },
        'dateChangeDialog.student'(v) {
            if (v) {
                this.dateChangeDialog.startDate = v.intervention_plan_intervention_start_date
                this.dateChangeDialog.endDate = v.intervention_plan_intervention_end_date
                this.dateChangeDialog.exitStatus = v.intervention_exit_status_id
            }
        },
        'addStudentDialog.show'(v) {
            if (v) {
                this.addStudentDialog.student = null
                this.addStudentDialog.date = this.$dayjs().format('MM/DD/YYYY')
            }
        },
        interventionPlanId: {
            handler(v) {
                if (v) this.loadInterventionPlan()
            },
            immediate: true
        },
        currentStudent(v) {
            if (v) {
                this.showAllYears = JSON.parse(window.localStorage.getItem('showAllYearsMonitor')) || false
                this.studentInterventionId = v.intervention_id //change intervention id when new student selected
                this.loadMonitors()
            }
        },
        'selectedPlan.included'(v) {
            if (v && v.length) {
                this.currentPlan = v[0]
                this.loadGroup(v[0])
            }
        },
        'phaseDialog.show'(v) {
            if (!v) {
                if (!this.currentStudent) {
                    this.loadGroup(this.currentPlan)
                }
            }
        },
        currentPlan: {
            handler(v) {
                if (v?.category_id) {
                    this.$store.commit('intervention/set', {key: 'categoryId', value: v.category_id})
                }
            },
            immediate: true
        },
        'selectedSchoolYear.included'(v) {
            this.loadMonitors()
        },
    },

    created() {
        this.$getUiSavedSearch('RECENT_SMART_FORMS')

        if (this.interventionYear) {
            this.selectedSchoolYear = {
                included: [this.interventionYear]
            }
        } else {
            this.selectedSchoolYear = {
                included: [this.currentYear]
            }
        }
        this.selectedUser = {
            included: [this.sessionUser.user]
        }

        this.loadPlans()

        let window = this.$store.getters['global/dockableWindows'].filter(w => w.component === 'intervention-workspace').pop()
        this.dockableWindowId = window.dockedWindowId
    },
    methods: {
        addMonitor() {
            this.monitorDialog.monitors = []
            this.monitorDialog.students = this.students.map(r => {
                return {
                    student_id: r.student_id,
                    student_full_name: r.student_full_name,
                    intervention_id: r.intervention_id, // used to add to existing intervention
                    school_id: r.school_id // used to get school-specific assessments
                }
            })
            this.monitorDialog.show = true
        },
        loadInterventionPlan() {
            if (this.interventionPlanId) {
                this.$modelGet('interventionPlanList', {intervention_plan_id: this.interventionPlanId})
                    .then(plans => {
                        if (plans.length) {
                            this.currentPlan = plans[0]
                            this.$refs.planSelector.selectItem(plans[0])
                            this.loadGroup({id: this.interventionPlanId})
                        }
                    })
            }
        },
        changeDates() {
            if (this.$refs.dateChangeDialogForm.validate()) {
                let payload = Object.assign({}, {
                    id: this.dateChangeDialog.student.student_intervention_plan_id,
                    intervention_id: this.dateChangeDialog.student.intervention_id,
                    intervention_plan_id: this.dateChangeDialog.student.intervention_plan_id,
                    intervention_plan_intervention_schedule_id: this.dateChangeDialog.student.intervention_plan_intervention_schedule_id,
                    intervention_plan_user_id: this.dateChangeDialog.student.intervention_plan_user_id,
                    student_id: this.dateChangeDialog.student.student_id,
                    start_date: this.dateChangeDialog.startDate,
                    end_date: this.dateChangeDialog.endDate,
                    intervention_exit_status_id: this.dateChangeDialog.exitStatus,
                    phase_name: this.dateChangeDialog.phaseName
                })
                this.$axios.post('studentInterventionPlans.php?action=update', {students: [payload]}).then(r => {
                    this.$ecResponse(r)
                    if (this.interventionPlanId) this.loadInterventionPlan()
                    else this.loadGroup(this.currentPlan)
                })
            }
        },
        startNewPhase() {
            if (this.currentPlan.category_id === 4) {
                this.$store.commit('set', {
                    module: 'intervention',
                    state: 'editBehaviorPlan',
                    value: this.currentPlan
                })
            }
            this.phaseDialog.show = true
            this.phaseDialog.selected = this.currentStudent ? this.currentStudent : null
        },
        // This function does the same thing as the behavior-progress-monitoring would do
        // We can't call $refs.(...).enterScores because it isn't mounted until user browsers to it
        enterScores() {
            let params = {
                intervention_plan_id: this.currentStudent.intervention_plan_id,
                student_monitor_plan_id: this.currentStudent.student_monitor_plan_id,
                student_id: this.currentStudent.student_id,
            }

            this.$axios.get('studentMonitors.php?action=get&' + this.$objectToParams(params))
                .then(resp => {
                    let mon = this.$ecResponse(resp, 'monitors')?.find(itm => itm.student_monitor_plan_id == params.student_monitor_plan_id)
                    if (mon) {
                        this.$dockableWindow({
                            component: 'point-sheet-monitoring',
                            attrs: {
                                params: {
                                    intervention_id: mon.intervention_id,
                                    student_id: mon.student_id,
                                    student_monitor_plan_id: mon.student_monitor_plan_id
                                }
                            }
                        })
                    }
                })
        },
        addStudents() {
            this.$axios.post('studentInterventionPlans.php?action=create', {
                students: [{
                    student_id: this.addStudentDialog.student,
                    start_date: this.$dayjs(this.addStudentDialog.date).format('YYYY-MM-DD'),
                    intervention_plan_id: this.currentPlan.id
                }]
            }).then(r => {
                let success = this.$ecResponse(r)
                this.loadGroup(this.currentPlan)
            })

        },
        loadPlans() {
            this.$modelGet('interventionPlanList', {
                school_year_id: this.selectedSchoolYear.included.map(r => r.id),
                user_id: this.selectedUser.included.map(r => r.id)
            })
                .then(plans => {
                        this.plans = this.$_.uniqBy(plans, 'id')

                        if (this.currentPlan) {
                            this.groupView = false
                            let found = this.plans.find(p => p.id === this.currentPlan.id)
                            this.$refs.planSelector.selectItem(found)
                            this.currentPlan = found
                        }
                })
        },
        confirmDeletePlan(action, deleteMonitors = 0) {
            if (!action) return
            let me = this

            this.$axios.post(`interventionPlan.php?action=${action}`, {
                interventions: [{
                    intervention_plan_id: this.currentPlan.id,
                    delete_probes: deleteMonitors
                }]
            })
                .then(r => {
                    let actions = [{
                        text: 'Cancel',
                    }, {
                        text: 'Delete Plans',
                        usage: 'warning',
                        onClick: () => {
                            me.confirmDeletePlan('delete', 0)
                        }
                    }]

                    if (r.data.delete_probe_text != "null") {
                        actions.push({
                            text: 'Delete Plans With Monitors',
                            usage: 'warning',
                            onClick: () => {
                                me.confirmDeletePlan('delete', 1)
                            }
                        })
                    }
                    if (r.data.success && action == 'delete_confirm') {
                        this.$messageBox({
                            title: 'Confirm Delete',
                            persistent: true,
                            maxWidth: 800,
                            message: r.data.msg + (r.data.delete_probe_text != "null" ? '<br><br><b>' + r.data.delete_probe_text.replace(/\'/g, '') + '</b>' : ''),
                            actions: actions
                        })
                    } else {
                        this.$ecResponse(r)
                        this.selectedPlan = {included: [], excluded: []}

                        this.$store.commit('global/removeDockedWindow', this.$store.getters['global/dockableWindows'].filter(w => w.dockedWindowId === me.dockableWindowId).pop())

                        this.currentPlan = null

                        this.loadPlans()

                        this.updateInterventionWindows()

                        this.$emit('interventionDeleted')
                    }
                })
        },
        updateInterventionWindows() {
            Object.entries(this.$root.$children[0].$refs).filter((entry) => {
                let component = Array.isArray(entry[1]) ? entry[1].pop() : entry[1]
                if (entry[0].includes('dockable-window') && component != undefined) {
                    if (component.$options.name === 'InterventionAnalysis' && component.dockableWindowId != this.dockableWindowId) {
                       component.loadPlans()
                    }
                }
            })
        },
        fidelityReport() {
            let host = this.$axios.defaults.baseURL
            this.pdfUrl = `${host}/reportCreate.php?type=intervention_fidelity&student_id=${this.currentStudent.student_id}&intervention_id=${this.currentStudent.intervention_id}&v=2`
            this.printing = true
        },
        creationReport() {
            let host = this.$axios.defaults.baseURL
            this.pdfUrl = `${host}/reportCreate.php?type=intervention_creation&student_id=${this.currentStudent.student_id}&intervention_id=${this.currentStudent.intervention_id}&v=2`
            this.printing = true
        },
        loadGroup(group, reload = false) {
            if (!reload) {
                this.currentStudent = null
            }
            this.students = []
            this.$modelGet('interventionPlanStudent', {intervention_plan_id: group.id})
                .then(s => {
                    this.students = s

                    if (!reload) {
                        if (this.studentId) this.currentStudent = s.find(x => x.student_id == this.studentId)
                        else if (this.students.length) this.currentStudent = this.students[0]
                    }

                })
        },
        loadStudent(s, newPhase = false) {
            this.groupView = false
            this.currentStudent = s
            if (newPhase) this.activeTab = 0
        },
        loadMonitors(monitor_plan_id = null) {
            if (!this.currentStudent) return

            // load monitor data for Link To Intervention functionality
            this.currentMonitors = []
            this.$axios.get('studentMonitors.php?action=get&academic=1&property=roi_target_list&probes_only=1&student_id=' + this.currentStudent.student_id)
                .then(response => {
                    const newMonitors = []
                    response.data.monitors?.forEach(monitor => {
                        if (monitor.intervention_id === this.studentInterventionId) {
                            newMonitors.push(monitor)
                        }
                    })
                    this.currentMonitors = newMonitors
                })

            this.chartData = []
            if (this.currentPlan.category_id !== 4) {
                this.$setLoading(true)
                this.$modelGet('academicMonitorChart', {
                    student_id: this.currentStudent.student_id,
                    intervention_plan_id: this.currentStudent.intervention_plan_id,
                    school_year_id: this.showAllYears ? null : this.selectedSchoolYear.included.map(r => r.id),
                    student_monitor_plan_id: monitor_plan_id
                }).then(r => {
                    if(monitor_plan_id) {
                        // want to pass back value of show all years for specific chart
                        r.find(x => x.student_monitor_plan_id == monitor_plan_id).showAllYears = this.showAllYears
                    }
                    this.chartData = r
                }).finally(() => {
                    this.$setLoading(false)
                })
            }
        },
        onReloadProgressMonitoring() {
            this.loadMonitors()
        },
        getFidelityColor(v) {
            if (v < 70) return 'red'
            if (v < 80) return 'orange'
            return 'green'
        },
        getStudentImg(student_id) {
            return this.$axios.defaults.baseURL + this.$models.getUrl('studentImage', 'read') + '&id=' + student_id
        },
        submitNewPhase(student) {
            if (student) {
                this.loadStudent(student, true)
                this.reloadPlanHistory = true
            }
        },
        setInterventionId(v) {
            this.interventionId = v
        },
        showAllYrsForMonitor(showAllYrs, monitor_plan_id) {
            this.showAllYears = showAllYrs
            this.loadMonitors(monitor_plan_id)
        },
        giveTitleIfTruncated(e) {
            if (e.target.offsetWidth < e.target.scrollWidth) {
                e.target.title = e.target.textContent
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.student-list {
    border-right: 1px solid #dddddd;
    width: 327px;

    .group-title {
        cursor: pointer;
        padding: 8px;
        border-bottom: 1px solid #dddddd;

        &-icon {
            background: #7E8494;
            border-radius: 20px;
            width: 28px;
            height: 28px;
            text-align: center;
            padding: 3px 0 0 0;
            color: white;
        }

        &-name {
            padding-left: 16px;
            font-size: 12px;
            line-height: 28px;
            color: #050F2D;
        }

        &-tool {
            width: 24px;
            height: 24px;
            padding-top: 2px;
            border-radius: 20px;
            text-align: center;
            cursor: pointer;
        }
    }

    .student-row {
        line-height: 42px;
        height: 42px;
        border-bottom: 1px solid #dddddd;
        cursor: pointer;

        &-selected {
            background: var(--fe-selected);
        }
    }

    .student-avatar {
        width: 46px;
        text-align: center;
    }

    .student-name {
        font-size: 12px;
        line-height: 42px;
        color: #050F2D;
        padding-left: 8px;
        border-right: 1px solid #dddddd;
        width: 170px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }

    .student-on-track {
        text-align: center;
        width: 48px;
        border-right: 1px solid #dddddd;
    }

    .student-fidelity {
        text-align: center;
    }
}

.student-selector {
    font-weight: bold;
    cursor: pointer;
    text-decoration: underline;
    color: rgb(0, 108, 144);
}

.ec-collections-main {
    background: white;
}

.small-tab {
    text-transform: none;
    letter-spacing: normal;
}

.small-tab::before {
    border-radius: 4px;
}

.ec-collection {
    &-tabs {
        ::v-deep .v-tabs-slider {
            border-radius: 1.5px;

            &-wrapper {
                height: 3px !important;
            }
        }
    }
}

.pdf-container {
    line-height: 28px;
    border: 1px solid #dddddd;
    margin: 16px;
    border-radius: 4px;
    cursor: pointer;

    &:hover {
        background: var(--fe-accent);
    }
}
</style>
