<template>
  <div>
    <h3 style="font-size: 18px; margin-bottom: 4px;">Communication Settings</h3>
    <div>Choose to translate forms and have guardians acknowledge reviewing forms.</div>
    <div>
      <v-checkbox 
        data-test="response-required-checkbox"
        class="mt-12" 
        v-model="responseRequired"
        @change="emitResponseRequired"
      >
        <template v-slot:label>
          <span data-test="response-required-label" style="font-size: 14px;">
            Prompt guardians to acknowledge they have reviewed each form.
          </span>
        </template>
      </v-checkbox>
    </div>
    <fe-btn
      data-test="settings-back-btn"
      style="margin-top: 20px; margin-left: -4px;"
      usage="secondary"
      @click="$emit('goToReview')"
    >
      Back
    </fe-btn>
    <fe-btn
      data-test="settings-continue-btn"
      style="margin-top: 20px; margin-left: -4px;"
      usage="primary"
      @click="$emit('goToPreview')"
    >
      Continue
    </fe-btn>
  </div>
</template>
<script>
export default {
  name: 'CommunicationSettings',
  data() {
    return {
      responseRequired: false,
    };
  },
  methods: {
    emitResponseRequired() {
      this.$emit('response-required-changed', this.responseRequired)
    },
  },
}
</script>
