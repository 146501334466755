<template>
    <div class="d-flex flex-fill flex-column flex-grow-1 flex-shrink-1" style="overflow: hidden">
        <div :class="Object.keys(urlParams).length ? 'mt-5 pl-5' : ''">
            <fe-mask v-model="loading" showLoader/>

            <div class="d-flex justify-space-between" v-if="Object.keys(urlParams).length">
                <div class="headline pl-5">Action Items</div>
                <div class="top-buttons">
                    <fe-btn usage="primary" @click="editActionItem()">
                        New Action Item
                    </fe-btn>
                </div>
            </div>

            <div v-if="!localItems.length && !loading" style="width: 100%;">
                <fe-overlay
                    image="/images/magnifying_glass.png"
                    :text="emptyStateText"
                    header=""
                    color="transparent"
                    :absolute="false"
                />
            </div>

            <div v-else style="width: 100%">
                <div class="d-flex flex-row wrap justify-space-between mt-5" style="height: 63px;">
                    <template v-for="filter in allStatusFilters">
                        <fe-card
                            class="flex-column flex-fill ai-card-main ml-3"
                            noTitle
                        >
                            <template v-slot:body>
                                <div class="ai-status-filter-parent">
                                    <div class="ai-status-filter-color mr-2">
                                        <v-icon small :color="filter.color">fas fa-square</v-icon>
                                    </div>
                                    <div class="subheading ai-status-filter-name">
                                        {{filter.name}}
                                    </div>
                                    <div class="ai-status-filter-count">
                                        <div class="count">{{filter.count}}</div>
                                        <span class="caption">Action Item{{filter.count !== 1 ? 's' : ''}}</span>
                                    </div>
                                </div>
                            </template>
                        </fe-card>
                    </template>
                </div>
                <div style="position:absolute; margin: 20px 0 0 10px;">
                    <fe-filter-btn
                        v-if="assigneeFilters.length"
                        :items="assigneeFilters"
                        :title="!filters.assignees.included.length ? 'All Assignees' : 'Assignees'"
                        v-model="filters.assignees"
                        itemValue="id"
                        itemText="name"
                        showFirstSelection
                    />
                    <fe-filter-btn
                        v-if="datesFilters.length"
                        :items="datesFilters"
                        :title="!filters.dates.included.length ? 'All Dates Due' :'Dates Due'"
                        v-model="filters.dates"
                        itemValue="id"
                        itemText="name"
                        showFirstSelection
                    />
                    <fe-filter-btn
                        v-if="timesFilters.length"
                        :items="timesFilters"
                        :title="!filters.times.included.length ? 'All Times Due' : 'Times Due'"
                        v-model="filters.times"
                        itemValue="id"
                        itemText="name"
                        showFirstSelection
                    />
                    <fe-filter-btn
                        v-if="creatorsFilters.length"
                        :items="creatorsFilters"
                        :title="!filters.creators.included.length ? 'All Creators' : 'Creators'"
                        v-model="filters.creators"
                        itemValue="id"
                        itemText="name"
                        showFirstSelection
                    />
                    <fe-filter-btn
                        v-if="statusFilters.length"
                        :items="statusFilters"
                        :title="!filters.statuses.included.length ? 'All Statuses' : 'Statuses'"
                        v-model="filters.statuses"
                        itemValue="id"
                        itemText="name"
                        showFirstSelection
                    />
                </div>
                <list-view
                    :items="filteredItems"
                    @delete="confirmDelete"
                    @update="updateActionItem"
                    @edit="editActionItem"
                    @add-assignee="addAssignee"
                    @submit-note="submitNote"
                    @delete-note="deleteNote"
                    class="ml-3 mt-5"
                    :actionPlan="actionPlan"
                    :unselectRows="unselectRows"
                />
            </div>

            <edit-action-item
                v-if="showCreateDialog"
                @dismiss="dismiss()"
                @save="dismiss()"
                activeUsersOnly
                :editing="editing"
                :extraParams="extraParams"
                :goals="goals"
            />

            <fe-crud
                ref="crud"
                :config="$models.task"
                :readParams="readParams"
                :autoload="false"
                @read="localItems = $event"
            />
            <fe-crud
                ref="taskAssigneeCrud"
                :config="$models.taskAssignee"
            />
            <fe-crud
                ref="taskNotesCrud"
                :config="$models.taskNote"
            />
        </div>
    </div>
</template>

<script>
import ListView from './ListView'
import EditActionItem from './EditActionItem'
import {mapState} from 'vuex'
import IFrameRenderer from '@/components/common/IFrameRenderer'

export default {
        name: 'ActionItems',

        components: {ListView,EditActionItem,IFrameRenderer},

        props: {
            tab: {type: String, default: 'all'},
            refresh: {type: Boolean, default: false},
            readParams: {type: Object, default: () => ({})},
            items : { type: Array, default: () => ([])},
            urlParams: {type: Object, default: () => ({})},
            goals: {type: Array, default: () => []},
            actionPlan: {type: Boolean, default: false},
        },

        data() {
            return {
                localItems: [],
                allStatusFilters: [
                    { id: 1, name: 'To Do', color: '#2B87FF', count: 0 },
                    { id: 4, name: 'In Progress', color: '#0EA449', count: 0 },
                    { id: 2, name: 'On Hold', color: '#FFA200', count: 0 },
                    { id: 3, name: 'Done', color: '#746DE0', count: 0 }
                ],
                tstamp: new Date().getMilliseconds(),
                filters: {
                    assignees: { included: [] },
                    dates: { included: [] },
                    times: { included: [] },
                    creators: { included: [] },
                    statuses: { included: [] }
                },
                assigneeFilters: [],
                datesFilters: [],
                timesFilters: [],
                creatorsFilters: [],
                statusFilters: [],
                filterStatus: null,
                loading: true,
                showCreateDialog: false,
                editing: null,
                extraParams: {},
                unselectRows: false,
            }
        },

        computed: {
            ...mapState('global', ['sessionUser', 'userPreferences']),
            emptyStateText() {
                return this.tab == 'all'
                    ? 'You do not have any Action Items assigned to you. <br>When someone assigns one to you it will appear here.'
                    : 'You do not have any Action Items. <br>Create one by selecting New Action Item above.'
            },
            filteredItems() {
                let me = this
                let items = me.$_.cloneDeep(me.localItems)
                // if any of the filters are selected, check if the any item (row) matches the filter(s) and then return
                if(Object.keys(me.filters).some(function(key){ return me.filters[key]['included'].length != 0 })) {
                    items = items.filter(item => {
                        return me.matchesAssigneeFilter(item) && me.matchesDateFilter(item) && me.matchesTimeFilter(item)
                            && me.matchesCreatorFilter(item) && me.matchesStatusFilter(item)
                    })
                }
                return items
            },
            path() {
                return '#/ActionItems'
            },
            cfg() {
                let me = this
                let p = {...this.$props.urlParams}
                p.user_id = this.sessionUser.user.id

                return {
                    path: this.path,
                    params: p,
                    events: {
                        'ai-load-smartform': function(rec) {
                            me.$dockableWindow({
                                name: rec.form_instance_name,
                                data: rec,
                                component: 'smart-form',
                                attrs: {
                                    id: rec.form_instance_id
                                },
                            })
                        },
                        'action-item-close': function() {
                            me.$emit('close')
                        },
                        delete() {
                        },
                        save(response) {
                            me.$emit('save', response)
                        }
                    },
                    identifier: this.tstamp
                }
            }
        },

        watch: {
            localItems: {
                deep: true,
                handler(v) {
                    this.loading = false
                    if(v) {
                        this.setStatusCounts()
                        // push data from each row into the filters and sort
                        v.forEach((item, index) => {
                            item.assignees.forEach(assignee => {
                                if(!this.assigneeFilters.find(x => x.name == assignee.user_full_name)) {
                                    this.assigneeFilters.push({id: assignee.id, name: assignee.user_full_name})
                                }
                            })
                            let date = this.$dayjs(item.due_dt).format('MM/DD/YYYY')
                            if(!this.datesFilters.find(x => x.name == date) && date !== 'Invalid date') {
                                this.datesFilters.push({id: item.id, name: date})
                            }
                            let time = this.$dayjs(item.due_time, 'HH:mm:ss').format('h:mm A')
                            if(!this.timesFilters.find(x => x.name == time) && time !== 'Invalid date') {
                                this.timesFilters.push({id: item.id, name: time})
                            }
                            if(!this.creatorsFilters.find(x => x.name == item.created_by_full_name)) {
                                this.creatorsFilters.push({id: item.id, name: item.created_by_full_name})
                            }
                            let name = this.allStatusFilters.find(y => y.id == item.task_status_id).name
                            if(!this.statusFilters.find(x => x.name == name)) {
                                this.statusFilters.push({id: index, name: name})
                            }
                        })
                        this.assigneeFilters = this.nameSort(this.assigneeFilters)
                        this.datesFilters = this.dateSort(this.datesFilters)
                        this.timesFilters = this.timeSort(this.timesFilters)
                        this.creatorsFilters = this.nameSort(this.creatorsFilters)
                        this.statusFilters = this.nameSort(this.statusFilters)
                    }
                }
            },
            refresh(v) {
                if(v) {
                    this.$refs.crud.read()
                    this.$emit('updated')
                }
            },
            items: {
                deep: true,
                handler(v) {
                    if(v) {
                        this.localItems = this.$_.cloneDeep(this.items)
                    }
                }
            },
            urlParams: {
                deep: true,
                handler(v) {
                    if(Object.keys(v).length) {
                        this.readParams = Object.assign(this.readParams, v)
                    }
                }
            }
        },

        mounted() {
            if(Object.keys(this.urlParams).length) {
                this.readParams = Object.assign(this.readParams, this.urlParams)
            }
            if(this.items.length) {
                this.localItems = this.$_.cloneDeep(this.items)
            } else {
                this.$refs.crud.read()
            }
        },

        methods: {
            setStatusCounts() {
                this.allStatusFilters.forEach((filter) => {
                    filter.count = 0
                })
                this.filteredItems.forEach(x => {
                    this.allStatusFilters.find(f => f.id == x.task_status_id).count++
                })
            },
            confirmDelete(selectedRows) {
                this.$confirmDelete(selectedRows, () => {
                    this.$refs.crud.destroy(selectedRows)
                        .then(() => {
                            this.$refs.crud.read()
                            this.$emit('dismiss')
                        })
                })
            },
            updateActionItem(type, payload, rows) {
                this.unselectRows = false
                this.$refs.crud.update(payload)
                    .then(() => {
                        if(rows.length > 1) {
                            rows.forEach((node,index) => {
                                node.setDataValue(type, payload[index][type])
                                if(type == 'task_status_id') {
                                    this.allStatusFilters.find(x => x.id == payload[index][type]).count++
                                    this.allStatusFilters.find(x => x.id == node.oldStatusVal).count--
                                }
                            })
                        } else {
                            if(rows.node) {
                                rows.node.setDataValue(type, payload[type])
                                if(type == 'task_status_id') {
                                    this.allStatusFilters.find(x => x.id == payload[type]).count++
                                    this.allStatusFilters.find(x => x.id == rows.value).count--
                                }
                            } else {
                                rows[0].setDataValue(type, payload[0][type])
                                if(type == 'task_status_id') {
                                    this.allStatusFilters.find(x => x.id == payload[0][type]).count++
                                    this.allStatusFilters.find(x => x.id == rows[0].oldStatusVal).count--
                                }
                            }
                        }
                        this.$emit('dismiss')
                        this.unselectRows = true
                    })
            },
            addAssignee(row, assignee) {
                this.$refs.crud.update(row).then(() => {
                    let taskId = row.id
                    if (assignee) {
                        let add = this.$_.cloneDeep(assignee)
                        add.task_id = taskId
                        add.user_id = assignee.id
                        add.task_status_id = 1
                        this.$refs.taskAssigneeCrud.create(add)
                            .then(() => {
                                this.$refs.crud.read()
                                this.$emit('dismiss')
                            })
                    }
                })
            },
            submitNote(rec) {
                this.$refs.taskNotesCrud.create({ task_notes: [rec] })
                    .then(() => {
                        this.$refs.crud.read()
                    })
            },
            deleteNote(rec, taskId) {
                this.$confirmDelete(1, () => {
                    this.$refs.taskNotesCrud.destroy({task_notes: [rec]})
                        .then(() => {
                            let index = this.$_.findIndex(this.localItems, x => x.id === taskId)
                            if (index >= 0) {
                                this.localItems[index].notes = this.localItems[index].notes.filter(x => x.id !== rec.id)
                            }
                        })
                })
            },
            matchesAssigneeFilter(item) {
                if(!this.filters.assignees.included.length) return true
                let names = this.filters.assignees.included.map(fltr => fltr.name)
                return !!item.assignees.find(itm => names.includes(itm.user_full_name))
            },
            matchesDateFilter(item) {
                if (!this.filters.dates.included.length) return true
                let dates = this.filters.dates.included.map(fltr => this.$dayjs(fltr.name).format('YYYY-MM-DD'))
                return !!dates.includes(item.due_dt)
            },
            matchesTimeFilter(item) {
                if (!this.filters.times.included.length) return true
                let times = this.filters.times.included.map(fltr => this.$dayjs(fltr.name, 'h:mm A').format('HH:mm:ss'))
                return !!times.includes(item.due_time)
            },
            matchesCreatorFilter(item) {
                if(!this.filters.creators.included.length) return true
                let names = this.filters.creators.included.map(fltr => fltr.name)
                return !!names.includes(item.created_by_full_name)
            },
            matchesStatusFilter(item) {
                if(!this.filters.statuses.included.length) return true
                let statuses = this.filters.statuses.included.map(fltr => fltr.name)
                return !!statuses.includes(this.allStatusFilters.find(y => y.id == item.task_status_id).name)
            },
            nameSort(items) {
                if (items.length <= 1) return items
                return items.sort((a, b) => {
                    if (a.name.toLowerCase() < b.name.toLowerCase()) {
                        return -1
                    } else if (a.name.toLowerCase() > b.name.toLowerCase()){
                        return 1
                    }
                })
            },
            dateSort(items) {
                if (items.length <= 1) return items
                return items.sort((a, b) => {
                    if (this.$dayjs.utc(a.name).format('YYYY-MM-DD').valueOf() < this.$dayjs.utc(b.name).format('YYYY-MM-DD').valueOf()){
                        return -1
                    } else if (this.$dayjs.utc(a.name).format('YYYY-MM-DD').valueOf() > this.$dayjs.utc(b.name).format('YYYY-MM-DD').valueOf()) {
                        return 1
                    }
                })
            },
            timeSort(items) {
                return items.sort((a, b) => {
                    if (this.$dayjs(a.name, 'h:mm A').format('HH:mm:ss') < this.$dayjs(b.name, 'h:mm A').format('HH:mm:ss')){
                        return -1
                    } else if (this.$dayjs(a.name, 'h:mm A').format('HH:mm:ss') > this.$dayjs(b.name, 'h:mm A').format('HH:mm:ss')) {
                        return 1
                    }
                })
            },
            dismiss() {
                this.showCreateDialog = false
                this.editing = null
                this.$refs.crud.read()
                this.$emit('dismiss')
            },
            editActionItem(item) {
                this.editing = item
                this.showCreateDialog = true
                // pass Meetings data to edit items
                this.extraParams = this.urlParams
            }
        }
    }
</script>

<style lang="scss">
    .ai-card-main .v-card__text {
        padding: 0 10px !important;
    }
    .filtered {
        border: 1px solid #2B87FF;
    }
    .ai-status-filter-parent {
        display: flex;
        align-items: center;
        padding: 10px;
        width: 100%;
    }
    .ai-status-filter-name {
        flex: 1;
        font-weight: bold;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .ai-status-filter-color {
        width: 20px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .ai-status-filter-count {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: right;
    }
    .ai-status-filter-count .count {
        font-size: 1.75rem;
    }
</style>
