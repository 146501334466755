<template>
    <div class="flex-fill flex-column">
        <div class="flex-row d-block mb-4" style="width: 100%;" v-if="selected && selected.name === 'Students'">
            <v-tabs height="36" v-model="tab" class="mb-0 student-tabs" noPadding :showArrows="true">
                <v-tab
                    v-for="(item, i) in results"
                    :key="i"
                    :href="`#tab-${i}`"
                    @click="tabClicked(item, lineSelected)"
                >{{ item.name }}
                </v-tab>
            </v-tabs>
        </div>

        <div ref="chartParentWrapper" class="d-flex flex-fill">
            <fe-left-menu-layout
                class="data-wall-left-menu"
                :menuItems="menu.filter(r=>!r.hidden)"
                v-model="selected"
                menuWidth="200px"
                :style="[{'max-width': '200px' }]"
            />

            <div class="flex-fill flex-column drilldown-container" v-show="selected && selected.name === 'Students'">
                <div class="d-flex flex-fill flex-row pt-2">
                    <fe-grid
                        ref="grid"
                        :columnDefs="columnDefs"
                        :rowData="filteredStudentsData"
                        :searchBar="false"
                        :showAddRowBtn="false"
                        :showDownload="false"
                        displayDensity="small"
                        :toggleCheckboxOnCellClick="false"
                        class="col col-6 pa-0"
                        :flexColumns="false"
                        :pinnedTopRowData="studentSummaryRow"
                        :emptyState="studentVisibilityEmptyState"
                        @selectionChanged="selectionChanged"
                        @cellMouseOver="doCellOver"
                        @cellMouseOut="doCellOut"
                    >
                        <template #left-toolbar-items>
                            <span class="tab-display pt-1 ml-3" v-if="selected && selected.name">{{ selected.name }}</span>

                            <fe-info-tip
                                    v-if="isStudentsDataRestrictedByVisibility"
                                    class="mx-2" style="display:inline-block;"
                                    tooltip="Some students in this Performance Band are not displayed because you do not have access to them."
                            />

                            <fe-remote-combo
                                    v-if="filteredDescriptors.length"
                                    class="pl-4 pt-1"
                                    title="Target Descriptor"
                                    :items="filteredDescriptors"
                                    itemValue="id"
                                    itemText="name"
                                    v-model="selectedFilter"
                                    :multiple="false"
                                    placeholder="Change Performance Band"
                                    style="min-width: 250px;"
                                    @input="applyFilters"
                            />

                            <fe-btn
                                    @click="tagStudents"
                                    style="margin-top: 2px;" usage="secondary" :disabled="!tagEnabled"
                            >
                                Tag Students
                            </fe-btn>
                        </template>
                    </fe-grid>

                    <fe-student-card
                        ref="studentCard"
                        :style="{
                            position: 'absolute',
                            left: `${studentCard.left}px`,
                            top: `${studentCard.top}px`,
                            padding: '1px 6px 1px 6px',
                            zIndex: '10'
                        }"
                        :imageUrl="studentCard.imageUrl"
                        :studentRecord="studentCard.studentRecord"
                        v-show="studentCard.show"
                        fullNameField="student_full_name"
                        schoolField="school_name"
                        gradeField="grade_desc"
                        genderField="gender"
                        @close="() => {}"
                    />

                    <div v-if="!scoreDetails.length" class="d-flex col col-6 empty-state-container pa-0">
                        <fe-overlay v-if="isLoadingScoreDetails" loader/>

                        <v-layout v-else align-center justify-center column fill-height text-center>
                            <img width="340px" src="/images/rocket.png"/>
                            <div class="subheading">
                                <template v-if="isScoreDetailsLoaded">
                                    The student has no Score Details.
                                </template>

                                <template v-else>
                                    Select a student to show the Score Details.
                                </template>
                            </div>
                        </v-layout>
                    </div>

                    <fe-grid
                        ref="detailsGrid"
                        :columnDefs="detailsColumnDefs"
                        :rowData="scoreDetails"
                        :searchBar="false"
                        :showAddRowBtn="false"
                        :showDownload="false"
                        :showToolbar="false"
                        displayDensity="small"
                        class="col col-6 pa-0"
                    />

                    <fe-crud
                        ref="crud"
                        :config="$models.studentScoreDetail"
                        @read="onScoreDetailsRead"
                    />
                </div>
            </div>

            <div
                ref="chartWrapper"
                v-show="selected && selected.name === 'Subskills'"
                class="flex-fill flex-column drilldown-container mr-4"
                :style="{height: wrapperHeight + 'px', width: wrapperWidth + 'px'}"
            >
                <v-flex shrink class="ml-auto">
                    <div class="launchpad-nav mr-4 mt-2">
                        <fe-btn
                            usage="secondary"
                            class="launchpad-nav-option first"
                            :class="{'btn-selected': lineSelected}"
                            useIcon="fas fa-chart-line"
                            @click="tabClicked(selectedTab, true)"
                        >
                            Line Chart
                        </fe-btn>

                        <fe-btn
                            usage="secondary"
                            class="ml-auto launchpad-nav-option last"
                            :class="{'btn-selected': !lineSelected}"
                            useIcon="fas fa-chart-bar"
                            @click="tabClicked(selectedTab, false)"
                        >
                            Bar Charts
                        </fe-btn>
                    </div>
                </v-flex>

                <highcharts v-if="lineSelected && !emptyState" ref='chartRef' :options="localConfig"/>

                <div class="flex-fill flex-row flex-wrap" v-else-if="!lineSelected && !emptyState">
                    <div
                        class="d-block"
                        :class="`col col-` + cols" v-for="(cfg, i) in stackedCharts"
                        :key="`chart-` + i"
                    >
                        <benchmark-chart
                            :config='cfg'
                            :type="cfg.BASIC.length > 0 ? 'basic' : 'detailed'"
                            :border="true"
                            :key="cfg.key"
                            :overallRecord="cfg"
                            :performanceBands="performanceBands"
                            @bandNotFound="fetchPerformanceBands(true)"
                        />
                    </div>
                </div>

                <div v-else class="flex-fill align-center text-center">
                    <fe-empty-state
                        :style="{height: 200 + 'px'}"
                        image="/images/empty-state-horn.png"
                        header="No Chart"
                        text="No subskill data has been found."
                    />
                </div>
            </div>

            <div
                ref="longitudinalWrapper"
                v-show="selected && selected.name === 'Longitudinal'"
                class="flex-fill flex-column drilldown-container mr-4"
                :style="{ height: wrapperHeight + 'px', width: wrapperWidth + 'px'}"
            >
                <longitudinal-chart
                    :options="localConfig"
                    :selectedData="selectedTab"
                    :chartConfigData="longChartResponse"
                    :filterParams="longitudinalParams"
                    :chartHeight="containerHeight"
                    :chartWidth="wrapperWidth"
                />
            </div>

            <div
                ref="effectivenessWrapper"
                v-show="selected && selected.name === 'Effectiveness'"
                class="flex-fill flex-column drilldown-container mr-4"
                :style="{height: wrapperHeight + 'px', width: wrapperWidth + 'px'}"
            >
                <program-effectiveness :params="effectivenessParams"/>
            </div>

            <div
                ref="sdAnalyicsWrapper"
                v-show="selected && selected.name === 'Score Details'"
                class="flex-fill flex-column drilldown-container mr-4"
                :style="{height: wrapperHeight + 'px', width: wrapperWidth + 'px'}"
            >
                <score-detail-analytics :params="effectivenessParams"/>
            </div>
        </div>
    </div>
</template>

<script>
import {mapLocalState} from '@/util/vuexHelper'
import Highcharts from 'highcharts'
import {Chart} from 'highcharts-vue'
import BenchmarkChart from "@/components/charts/BenchmarkChart"
import LongitudinalChart from './LongitudinalChart'
import ScoreDetailAnalytics from '@/components/modules/analytics/scoreDetail'
import ProgramEffectiveness from './ProgramEffectiveness'
import windowOptionsMixin from '@/mixins/windowOptions'
import studentProfileMixin from '@/mixins/studentProfile'

export default {
    name: 'ChartDrilldown',

    components: {
        Chart,
        BenchmarkChart,
        LongitudinalChart,
        ProgramEffectiveness,
        ScoreDetailAnalytics
    },

    mixins: [windowOptionsMixin, studentProfileMixin],

    props: {
        drilldownData: {
            type: Object,
            return: () => {
            }
        },
        params: {
            type: Object,
            return: () => {
            }
        },
        record: {
            type: Object,
            return: () => {
            }
        },
        overallResults: {
            type: Array,
            default: () => []
        },
        currentCategory: {
            type: Object,
            return: () => {
            }
        },
        clickRecord: {
            required: true
        }
    },

    data() {
        let me = this
        return {
            alphaMaps: {},
            selected: null,
            descriptors: [],
            studentSummaryRow: [],
            filterSelection: null,
            isStudentsDataRestrictedByVisibility: false,
            tagEnabled: false,
            "columnDefs": [
                {
                    "colId": "checkbox",
                    "headerName": null,
                    "headerCheckboxSelection": true,
                    "checkboxSelection": true,
                    "pinned": "left",
                    "maxWidth": 70
                },
                {
                    "headerName": "Student",
                    "field": "student_full_name",
                    "sortable": true,
                    "editable": false,
                    onCellClicked: v => {
                        let window = me.$parent.$parent.$parent.$parent.$parent.visibleWindows[0].dockedWindowId
                        me.goToStudentProfile(v.data.student_id)
                        me.$parent.$parent.$parent.$parent.$parent.toggleWindow(true, window)
                    }
                },
                {
                    "headerName": "Score",
                    "field": "score",
                    "sortable": true,
                    "comparator": (a, b) => parseFloat(a) - parseFloat(b), // All students will have a score, so omit null-ish checks
                    "editable": false,
                    onCellClicked: v => {
                        me.cellClicked(v)
                    },
                    cellRenderer(v) {
                        let parsed = parseInt(v.value)
                        if (me.alphaMaps[parsed]) return me.alphaMaps[parsed]
                        return v.value
                    },
                    cellStyle(v) {
                        return {
                            backgroundColor: v.data.target_descriptor_color,
                            textAlign: 'right'
                        }
                    }
                },
                {
                    "headerName": "School",
                    "field": "school_name",
                    "sortable": true,
                    "editable": false,
                    "hide": true
                }
            ],
            "detailsColumnDefs": [
                {
                    "headerName": "Description",
                    "field": "score_detail_type_code",
                    "sortable": true,
                    "editable": false
                },
                {
                    "headerName": "Value",
                    "field": "score_detail_detail_value",
                    "sortable": true,
                    "editable": false
                }
            ],
            studentsData: [],
            studentsCopy: [],
            selectedItems: [],
            scoreDetails: [],
            isLoadingScoreDetails: false,
            isScoreDetailsLoaded: false,
            results: [],
            resultsCopy: [],
            subskillResults: [],
            tab: null,
            selectedTab: {},
            localConfig: {},
            chartRecord: [],
            fields: [],
            baseParams: {},
            colors: [],
            localHeight: null,
            chartTitle: '',
            title: '',
            lineSelected: true,
            stackedCharts: [],
            longChartResponse: {},
            emptyState: false,
            performanceBands: [],
            isFetchingPerformanceBands: false,
        }
    },

    computed: {
        ...mapLocalState(['students']),

        selectedFilter: {
            get() {
                return this.filterSelection || this.filteredDescriptors.find(dsc => dsc.id == this.params.target_descriptor_id) || {
                    id: -2,
                    name: 'All'
                }
            },
            set(v) {
                this.filterSelection = v
            }
        },

        targetSetId() {
            return this.params.target_set_id
        },

        selectedTargetType() {
            return this.params.selected_target_type
        },

        selectedTargetTypeId() {
            if (this.selectedTargetType == 'basic') {
                return 3
            } else if (this.selectedTargetType == 'detailed') {
                return 1
            } else {
                return null
            }
        },

        filteredDescriptors() {
            return (this.selectedTargetTypeId)
                ? this.descriptors.filter(dsc => dsc.target_type_id == this.selectedTargetTypeId || !dsc.target_type_id)
                : this.descriptors
        },

        filteredStudentsData() {
            return this.studentsData
        },

        studentVisibilityEmptyState() {
            if (this.isStudentsDataRestrictedByVisibility && this.studentsData.length === 0) {
                return {text: 'No student in this Performance Band can be displayed because you do not have access to any of them.'}
            } else {
                return null
            }
        },

        wrapperHeight() {
            return this.$refs.chartParentWrapper?.clientHeight - 16
        },

        wrapperWidth() {
            return this.$refs.chartParentWrapper?.clientWidth - 216
        },

        containerHeight() {
            return this.$refs.chartParentWrapper?.clientHeight - 100
        },

        subskillsWrapper() {
            return this.$refs.chartWrapper?.clientHeight - 52
        },

        effectivenessParams() {
            let p = {
                ...this.clickRecord.student_params, ...this.record.student_params
            }
            p.school_year_id = this.clickRecord.student_params.school_year_id
            delete p.data_point_name_id
            delete p.data_point_id
            return p
        },

        longitudinalParams() {
            if (!this.selectedTab) return {}

            let p = {
                ...this.clickRecord.student_params, ...this.record.student_params
            }

            delete p.data_point_id
            delete p.sub_category_parent_id
            delete p.group_by
            return p
        },

        cols() {
            let col = 6
            if (this.$vuetify.breakpoint.mdAndDown) {
                col = 12
            } else if (this.$vuetify.breakpoint.lgAndDown) {
                col = 6
            } else {
                col = 4
            }
            return col
        },

        menu() {
            return [
                {
                    name: 'Students',
                    rec: {id: 1, name: 'Students'}
                }, {
                    name: 'Subskills',
                    rec: {id: 2, name: 'Subskills'},
                    hidden: !this.tab || !this.subskillResults[parseInt(this.tab.replace('tab-', ''))]
                }, {
                    name: 'Effectiveness',
                    rec: {id: 3, name: 'Effectiveness'}
                }, {
                    name: 'Longitudinal',
                    rec: {id: 4, name: 'Longitudinal'}
                }, {
                    name: 'Score Details',
                    rec: {id: 5, name: 'ScoreDetailAnalytics'}
                }
            ]
        }
    },

    watch: {
        deep: true,
        selected(v) {
            if (!this.tab) return
            v.name === "Students" ? this.results = this.resultsCopy : this.results = this.subskillResults

            let result = this.results[parseInt(this.tab.replace('tab-', ''))]
            this.tabClicked(result, this.lineSelected)
        }
    },

    mounted() {
        let me = this

        if (!this.record.is_parent) {
            this.menu[1].hidden = true
        }
        let recCopy = this.$_.cloneDeep(me.$props.record)

        let allBands = []
        if (recCopy.DETAILED) {
            recCopy.DETAILED.forEach(r => {
                let found = allBands.find(f => f.name === r.name)
                if (!found) allBands.push(r)
            })
        }

        if (recCopy.BASIC) {
            recCopy.BASIC.forEach(r => {
                let found = allBands.find(f => f.name === r.name)
                if (!found) allBands.push(r)
            })
        }

        this.buildTabs(allBands)

        let activeTab = this.results.findIndex(t => {
            if (t.name === this.clickRecord.name) {
                return t
            }
        })
        this.tab = 'tab-' + activeTab

        this.$axios.get('alphaMap.php?action=get&property=detailed&' + this.$objectToParams({data_point_type_id: this.record.student_params.data_point_type_id}))
            .then(response => {
                let maps = this.$ecResponse(response, 'alpha_maps')
                if (maps && maps.length) {
                    if (maps[0].exclusions.indexOf(this.record.student_params.sub_category_id) != -1) {
                        this.alphaMaps = {}
                    } else {
                        maps[0].maps.forEach(m => {
                            this.alphaMaps[m.numeric_score] = m.alpha_score
                        })
                    }
                }

                if (!this.targetSetId) {
                    this.descriptors = [{id: -2, name: 'All'}]
                    this.tabClicked(this.results[activeTab], this.lineSelected)
                    this.selected = this.menu[0]
                } else {
                    let p = {
                        ...this.clickRecord.student_params, ...this.record.student_params
                    }

                    this.$modelGet('targetSetDescriptor', {
                        target_set_id: this.targetSetId,
                        data_point_id: p.data_point_id,
                        grade_id: p.grade_id,
                        include_aliases: true,
                    })
                        .then(r => {
                            r = r.concat([{id: -2, name: 'All'}])
                            this.descriptors = this.$_.sortBy(r, 'rank', 'ASC')

                            this.tabClicked(this.results[activeTab], this.lineSelected)
                            this.selected = this.menu[0]
                        })
                }
            })

        this.fetchPerformanceBands()
    },

    methods: {
        async fetchPerformanceBands(isResync = false) {
            // If this is to resync missing PBs, then there might be a ton of
            // charts with the same problem (e.g. 29 charts on district search).
            // As long as one of their emits gets processed, we should hope/expect
            // all of them will be brought up-to-date.
            if (isResync && this.isFetchingPerformanceBands) {
                return
            } else if (isResync) {
                console.warn('Performance Band not found, possibly out of sync; requesting new bands data...')
            }

            this.isFetchingPerformanceBands = true
            this.performanceBands = await this.$modelGet('userTargetSet')
            this.isFetchingPerformanceBands = false
        },

        loadData(data) {
            this.studentsData = data || []
            let total = 0
            if (data.length) {
                data.forEach(d => {
                    total += parseFloat(d.score)
                    d['school_year_id'] = this.params.school_year_id
                })
                total = total / data.length
            }

            if (this.alphaMaps[Math.floor(total)]) {
                total = this.alphaMaps[Math.floor(total)]
            } else {
                total = total.toFixed(1)
            }

            this.studentSummaryRow[0] = {
                no_click: true,
                student_full_name: 'Students (' + data.length + ')',
                score: 'Avg: ' + total
            }
        },

        tagStudents() {
            let me = this
            let sel = this.$refs.grid.gridApi.getSelectedRows()
            this.$store.commit('global/tagStudents', {
                show: true,
                students: sel,
                callback() {
                    me.$refs.grid.gridApi.deselectAll()
                }
            })
        },

        applyFilters() {
            let item = this.results[parseInt(this.tab.replace('tab-', ''))]
            if (item) this.tabClicked(item, this.lineSelected)
        },

        selectionChanged() {
            let selected = this.$refs.grid.gridApi.getSelectedRows()
            this.tagEnabled = !!selected.length
            if (selected.length === 1) {
                this.scoreDetails = []
                this.isLoadingScoreDetails = true
                this.$refs.crud.read({data_point_score_id: selected[0].data_point_score_id})
            } else {
                this.scoreDetails = []
                this.isLoadingScoreDetails = false
                this.isScoreDetailsLoaded = false
            }
        },

        cellClicked(cell) {
            let prevSelected = cell.api.getSelectedNodes()

            // Nothing was previously clicked, so auto-check this cell
            if (prevSelected.length === 0) {
                cell.node.setSelected(true)
            }

                // Clicking another row when exactly 1 previous row as clicked will
                // move the single checkbox to the new row (if a different row),
            // unless the user specifically clicks on the checkbox column
            else if (prevSelected.length === 1) {
                if (cell.column?.colId == 'checkbox') {
                    cell.node.setSelected(!cell.node.isSelected())
                } else {
                    prevSelected[0].setSelected(false)
                    if (prevSelected[0].id !== cell.node.id) {
                        cell.node.setSelected(true)
                    }
                }
            }

                // If more than 1 row is already selected, then clicking anywhere
                // on an additional row will simply toggle its checkbox state
            // without affecting the multiple checked rows
            else {
                cell.node.setSelected(!cell.node.isSelected())
            }
        },

        onScoreDetailsRead(data) {
            this.scoreDetails = data
            this.isLoadingScoreDetails = false
            this.isScoreDetailsLoaded = true // helps differentiate between [] no data for a given student and [] never even tried yet
        },

        buildTabs(records) {
            let arr = []
            let subskillsArr = []
            let me = this
            records.forEach(function (det, i) {
                let recObj = _.cloneDeep(me.$props.record)
                recObj.tabParams = det.student_params
                recObj.name = det.name
                recObj.data_point_id = det.student_params.data_point_id

                arr.push(recObj)

                if (recObj.subcat_params) {
                    let subCopy = _.cloneDeep(recObj)

                    subCopy.name = det.sort_fields.school_year + " Yearly"
                    subskillsArr[i] = subCopy
                }
            })


            this.subskillResults = subskillsArr
            this.results = arr
            this.resultsCopy = _.cloneDeep(this.results)
        },

        async tabClicked(e, lineChart) {
            if (e) this.selectedTab = e // The selected tab should only be changed if it's not null/hidden
            this.emptyState = false
            this.lineSelected = lineChart

            this.scoreDetails = []
            this.isLoadingScoreDetails = false
            this.isScoreDetailsLoaded = false

            let params = _.cloneDeep({...this.params})

            if (this.selected?.name === 'Students') {
                params = _.cloneDeep({...this.clickRecord.student_params, ...this.record.student_params})

                let p = {
                    ...this.clickRecord.student_params, ...this.record.student_params, ...e.tabParams
                }

                // Reload descriptors data for the selected tab
                await this.$modelGet('targetSetDescriptor', {
                    target_set_id: this.targetSetId,
                    data_point_id: p.data_point_id,
                    grade_id: p.grade_id,
                    include_aliases: true,
                }).then(r => {
                    r = r.concat([{id: -2, name: 'All'}])
                    this.descriptors = this.$_.sortBy(r, 'rank', 'ASC')
                })

                // If the selected descriptor on the previous tab is not enabled on the
                // new tab (example: school disables/hides the "above average" target descriptor
                // for 4th grade, but enables it for 3rd grade, and user had "above average"
                // selected), then we should reset the target descriptor dropdown to "All"
                if (!this.descriptors.find(des => des.id === this.selectedFilter?.id)) {
                    this.selectedFilter = this.descriptors[this.descriptors.length - 1]
                }

                if (this.selectedFilter) {
                    if (this.selectedFilter.id === -2) {
                        params.target_set_id = this.targetSetId
                        params.target_descriptor_id = null
                        params.target_type_id = [-1]
                    } else {
                        params.target_descriptor_id = this.selectedFilter?.id
                        params.target_type_id = []
                    }

                    if (this.selectedTargetTypeId && this.record.target_set_id) {
                        params.target_type_id.push(this.selectedTargetTypeId)
                    }
                } else {
                    this.selectedFilter = params.target_descriptor_id
                }

                Object.assign(params, e.tabParams)

                this.$setLoading(true)
                this.$axios.get('targetScores.php?action=get&property=students&' + this.$objectToParams(params, true))
                    .then((response) => {
                        if (response?.data) {
                            this.loadData(response.data.categories)
                            this.isStudentsDataRestrictedByVisibility = response.data.meta?.unfilteredTotal > response.data.categories?.length
                        }
                    }).finally(() => {
                    this.$setLoading(false)
                })
            } else if (this.selected?.name === 'Subskills' && e?.subcat_params) {
                Object.assign(params, e.subcat_params)
                if (e.grade_id) {
                    params.grade_id = e.grade_id
                }
                delete params.data_point_id
                delete params.target_set_id
                this.$setLoading(true)
                this.$axios.get('targetScores.php?action=get&' + this.$objectToParams(params, true))
                    .then((response) => {
                        if (lineChart) {
                            if (response.data.categories?.length) this.chartConfig(response.data.categories[0].trendline_charts[0])
                            else this.emptyState = true
                        } else {
                            if (response.data.categories?.length) {
                                this.stackedCharts = this.$_.flatten(response.data.categories.map(itm => itm.stacked_charts))
                            } else {
                                this.emptyState = true
                            }
                        }
                    }).finally(() => {
                    this.$setLoading(false)
                })
            } else if (this.selected?.name === 'Longitudinal') {
                let p = {...this.longitudinalParams}
                delete p.data_point_id
                delete p.target_descriptor_id

                this.$setLoading(true)
                this.$axios.get('targetScores.php?action=get&property=student_trendline&' + this.$objectToParams(p, true))
                    .then((response) => {
                        if (response?.data) {
                            this.localConfig = {}
                            this.longChartResponse = response.data.scores[0]
                            this.chartConfig(response.data.scores[0])
                        }
                    }).finally(() => {
                    this.$setLoading(false)
                })
            }
        },

        chartConfig(config) {
            let me = this
            let chartType = 'line'

            this.overallRecord = config
            this.chartRecord = [...this.overallRecord.chart_data]
            this.fields = this.overallRecord.fields
            this.seriesConfig = this.buildSeries(this.chartRecord, this.fields)
            this.localConfig = this.buildChart(config, this.seriesConfig, chartType)
        },

        buildSeries(data, fields, colors) {
            let me = this
            let categories = []
            let series = []

            for (let index in data) {
                let r = data[index]
                fields.forEach(function (field, fieldIndex) {
                    if (field == "name") return
                    let serie = me.$_.find(series, function (o) {
                        return o.name == field
                    })

                    if (!serie) {
                        serie = {
                            name: field,
                            color: colors ? colors[fieldIndex] : '',
                            data: []
                        }
                        series.push(serie)
                    }

                    serie.data.push(r[field] ? r[field] : r[field] === undefined ? null : 0)
                })
                categories.push(r.name)
            }

            return {
                categories: categories,
                series: series.reverse()
            }
        },

        buildChart(chartOptions, seriesConfig, chartType) {
            let me = this

            let cfg = {
                chart: {
                    type: chartType,
                    height: this.subskillsWrapper + 'px'
                },
                credits: {
                    enabled: false
                },
                navigation: {
                    buttonOptions: {
                        enabled: false
                    }
                },
                title: {
                    text: '',
                },
                xAxis: {
                    categories: seriesConfig.categories
                },
                yAxis: {
                    min: 0,
                    endOnTick: false,
                    title: {
                        text: 'Score'
                    },
                    stackLabels: {
                        enabled: true,
                        style: {
                            fontWeight: 'bold'
                        }
                    }
                },
                legend: {
                    align: 'right',
                    verticalAlign: 'bottom',
                    shadow: false
                },
                tooltip: {
                    pointFormat: '<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b> ({point.percentage:.0f}%)<br/>',
                    shared: true
                },
                plotOptions: {
                    column: {
                        maxPointWidth: 50,
                        stacking: 'percent',
                        dataLabels: {
                            enabled: false
                        }
                    }
                },
                series: seriesConfig.series
            }

            return cfg
        }
    }
}
</script>

<style lang="scss" scoped>
::v-deep .menu-title-toolbar {
    min-height: 0 !important;
    height: 0;
}

.tab-display {
    font-size: 24px;
}

::v-deep div.student-tabs .v-tab {
    text-transform: none !important;
    letter-spacing: normal;
    font-size: 12px;
}

::v-deep div.data-wall-left-menu .nav-container {
    width: 200px !important;
    border: none;

    .nav-container-items {
        padding-left: 8px
    }
}

.drilldown-container, .empty-state-container {
    border: 1px solid #e0e1e6;
    border-radius: 5px;
}

.drilldown-container ::v-deep .v-text-field__details {
    display: none;
}

.launchpad-nav {
    align-items: center;
    display: flex;
    height: 100%;

    .launchpad-nav-option {
        ::v-deep .fe-button {
            display: inline-block;
            color: var(--fe-hover) !important;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            text-align: center;
            margin: 4px 0;
            cursor: pointer;
            border-color: #e2e2e2 !important;
        }

        ::v-deep .fe-button:hover.secondary {
            background: transparent !important;
        }

        &.btn-selected ::v-deep .fe-button.secondary {
            background-color: rgba(0, 108, 144, 0.2) !important;
        }

        &.first ::v-deep .fe-button {
            border-radius: 4px 0 0 4px;
        }

        &.middle ::v-deep .fe-button {
            border-radius: 0;
        }

        &.last ::v-deep .fe-button {
            border-radius: 0 4px 4px 0;
        }

        &.standalone {
            border-radius: 4px;
            width: 71px;
            margin-left: 6px;
        }
    }

    &-educlimber {
        font-size: 16px;
        line-height: 19px;
        color: #FFFFFF;
        padding: 16px 16px 16px 10px;
    }
}
</style>
