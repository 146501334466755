<template>
    <v-layout column>
        <div v-if="ready">
            <div v-if="!hideNav" class="headline mb-3">
                District Information
            </div>

            <div class="d-flex">
                <fe-label class="label">District Name</fe-label>
                <v-text-field
                    v-model="info.name"
                    :rules="requiredRule"
                    validateOnBlur
                    flat
                    solo
                    dense
                    @change="update"
                />
            </div>

            <div class="d-flex mb-3">
                <fe-label class="label">District Logo</fe-label>
                <v-img
                    :src="$axios.defaults.baseURL + 'logo.php?' + random"
                    max-height="50"
                    max-width="50"
                    class="mr-4"
                />
                <fe-btn @click="$refs.logoInput.click()">
                    Upload Logo
                </fe-btn>
                <input
                    name="logoFile"
                    type="file"
                    ref="logoInput"
                    style="display: none"
                    @change="handleLogoUpload"
                />
                <fe-info-tip
                    tooltip="After uploading the file, the Logo Url path will change. You may have to clear the cached image by holding shift while refreshing the page to see any changes in forms and charts. Maximum file size is 1mb."
                />
            </div>

            <div style="font-size: 14px;"><b>Customize your District's Login Message</b></div>
            <fe-editor
                v-model="info.login_message"
                ref="editor"
                :toolbarExtras="['|', 'fontFamily', 'alignment', '|', 'redo', 'undo']"
                :showRemainingCount="false"
                :showErrorMsg="false"
                :characterLimit="500000"
                submittable
                @input="editorInput"
                @submit="update"
            />

            <v-row class="flex-grow-1 mt-3">
                <v-col cols="6">
                    <div v-if="manageDistrict" class="d-flex">
                        <fe-label class="label">Login ID</fe-label>
                        <v-text-field
                            v-model="info.login_id"
                            flat solo dense
                            @change="update"
                        />
                    </div>
                    <div class="d-flex">
                        <fe-label class="label">Street</fe-label>
                        <v-text-field v-model="info.street" flat solo dense @change="update"/>
                    </div>
                    <div class="d-flex">
                        <fe-label class="label">City</fe-label>
                        <v-text-field v-model="info.city" flat solo dense @change="update"/>
                    </div>
                    <div class="d-flex">
                        <fe-label class="label">State Code</fe-label>
                        <v-text-field v-model="info.state" flat solo dense @change="update"/>
                    </div>
                    <div class="d-flex">
                        <fe-label class="label">Zip Code</fe-label>
                        <v-text-field
                            v-model="info.zip" flat solo dense @change="update"
                            type="number"
                            :rules="[rules.zipcode.regex]"
                        />
                    </div>
                    <div class="d-flex">
                        <fe-label class="label">Phone Number</fe-label>
                        <v-text-field v-model="info.phone_number" flat solo dense @change="update"/>
                    </div>
                    <div class="d-flex">
                        <fe-label class="label">Fax Number</fe-label>
                        <v-text-field v-model="info.fax_number" flat solo dense @change="update"/>
                    </div>
                    <div class="d-flex">
                        <fe-label class="label">Time Zone</fe-label>

                        <fe-remote-combo
                            url="crud.php?property=TimeZone&action=get&active=1"
                            rootProperty="time_zone"
                            itemText="time_zone"
                            itemValue="time_zone"
                            byId
                            v-model="info.time_zone"
                            @change="update"
                        />
                    </div>

                    <div v-if="$hasSecurity('UPLOAD_DATA')">
                        <div style="font-size: 14px;"><b>sFTP Settings</b></div>
                        <div class="d-flex">
                            <fe-label>sFTP Username</fe-label>
                            <div class="ml-11 mt-1">{{info.sftp_username}}</div>
                        </div>

                        <div class="d-flex">
                            <fe-label>sFTP Password</fe-label>
                            <div id="sftp_password" class="flex-column ml-11 mt-1">
                                <div style="height: 20px;" v-html="pwdDisplay.name"></div>
                                <fe-btn
                                    style="margin-left: -8px;"
                                    :disabled="!pwdDisplay.set"
                                    :useIcon="showPassword ? 'far fa-eye-slash' : 'fas fa-eye'"
                                    usage="primary"
                                    @click="showPassword = !showPassword"
                                >
                                    {{ showPassword ? 'Hide Password' : 'ShowPassword' }}
                                </fe-btn>
                            </div>
                        </div>

                        <div class="d-flex">
                            <fe-label>sFTP Hostname</fe-label>
                            <div class="ml-10 mt-1">{{info.sftp_host}}</div>
                        </div>

                        <div class="d-flex">
                            <fe-label style="width: 80px;">sFTP Port</fe-label>
                            <div class="ml-11 mt-1">{{info.sftp_port}}</div>
                        </div>
                    </div>
                </v-col>
                <v-col cols="6">
                    <div v-if="manageDistrict" class="d-flex">
                        <fe-label class="label">Upload Directory</fe-label>
                        <v-text-field
                            v-model="info.upload_dir"
                            flat solo dense
                            @change="update"
                        />
                    </div>
                    <div v-if="manageDistrict" class="d-flex">
                        <fe-label class="label">Default FTP Directory</fe-label>
                        <v-text-field
                            v-model="info.ftp_dir"
                            flat solo dense
                            @change="update"
                        />
                    </div>
                    <div v-if="manageDistrict" class="d-flex">
                        <fe-label class="label">Login Format</fe-label>
                        <v-text-field
                            v-model="info.login_format"
                            flat solo dense
                            @change="update"
                        />
                    </div>
                    <div class="d-flex">
                        <fe-label class="label">Email Domain</fe-label>
                        <v-text-field v-model="info.email_domain" prefix="@" flat solo dense @change="update" />
                    </div>
                    <div class="d-flex">
                        <fe-label class="label">Alternate Domain</fe-label>
                        <v-text-field v-model="info.alt_domain" prefix="@" flat solo dense @change="update"/>
                    </div>
                    <div v-if="manageDistrict" class="d-flex">
                        <fe-label class="label">Logo Url</fe-label>
                        <v-text-field
                            v-model="info.logo_file"
                            flat solo dense
                            @change="update"
                        />
                    </div>
                    <div class="d-flex mb-7">
                        <fe-label class="label">Report Color 1</fe-label>
                        <fe-color-selector
                            v-model="info.primary_color"
                            @change="updateColor($event, 'primary_color')"
                        />
                    </div>
                    <div class="d-flex">
                        <fe-label class="label">Report Color 2</fe-label>
                        <fe-color-selector
                            v-model="info.secondary_color"
                            @change="updateColor($event, 'secondary_color')"
                        />
                    </div>
                </v-col>
            </v-row>

            <v-row class="flex-grow-1">
                <v-col cols="12">
                    <div style="font-size: 14px;"><b>District Settings</b></div>
                </v-col>

                <v-col cols="3">
                    <div class="d-flex">
                        <fe-label class="label toggle">Allow Google Calendar</fe-label>
                        <fe-switch v-model="info.google_calendar_enabled" @input="update('google_calendar_enabled')"/>
                    </div>
                </v-col>
                <v-col cols="3">
                    <div class="d-flex">
                        <fe-label class="label toggle">Enable Adjusted Attendance Rate</fe-label>
                        <fe-switch
                            v-model="prefs.ATTENDANCE_ADJUSTED_RATE"
                            @change="updatePref($event, 'ATTENDANCE_ADJUSTED_RATE')"
                        />
                    </div>
                </v-col>
                <v-col cols="3">
                    <div class="d-flex">
                        <fe-label class="label toggle">Do not connect scores on benchmark charts</fe-label>
                        <fe-switch v-model="info.hide_score_chart_lines" @input="update"/>
                    </div>
                </v-col>
                <v-col cols="3" v-if="prefs.CONSOLE_ATTENDANCE != null">
                    <div class="d-flex">
                        <fe-label class="label toggle">Enable Console SIS Calculation</fe-label>
                        <fe-switch
                            v-model="prefs.CONSOLE_ATTENDANCE"
                            @change="updatePref($event, 'CONSOLE_ATTENDANCE')"
                        />
                    </div>
                </v-col>
            </v-row>

            <v-row v-if="manageDistrict" class="flex-grow-1">
                <v-col cols="12">
                    <div style="font-size: 14px;"><b>Support Settings</b></div>
                </v-col>

                <v-col cols="3">
                    <div class="d-flex">
                        <fe-label class="label toggle">Keep Rosters Current</fe-label>
                        <fe-switch
                            v-model="prefs.ROSTER_PURGE"
                            @change="updatePref($event, 'ROSTER_PURGE')"
                        />
                    </div>

                    <div class="d-flex">
                        <fe-label class="label toggle">PS Portal</fe-label>
                        <fe-switch v-model="info.psportal_enabled" @input="update"/>
                    </div>

                    <div class="d-flex">
                        <fe-label class="label toggle">Disable Local Login</fe-label>
                        <fe-switch
                            v-model="prefs.DISABLE_LOCAL_LOGIN"
                            @change="updatePref($event, 'DISABLE_LOCAL_LOGIN')"
                        />
                    </div>
                </v-col>

                <v-col cols="3">
                    <div class="d-flex">
                        <fe-label class="label toggle">Achievement Dashboard</fe-label>
                        <fe-switch v-model="info.dashboard_enabled" @input="update"/>
                    </div>

                    <div class="d-flex">
                        <fe-label class="label toggle">Users Default to Inactive</fe-label>
                        <fe-switch
                            v-model="prefs.IMPORT_USERS_INACTIVE"
                            @change="updatePref($event, 'IMPORT_USERS_INACTIVE')"
                        />
                    </div>

                    <div class="d-flex">
                        <fe-label class="label toggle">District Active</fe-label>
                        <span v-if="info.active" style="color: #095C2A;"><i class="far fa-check mr-2" ></i>Active</span>
                        <span v-else style="color: #BF0D00;"><i class="far fa-times mr-1" ></i>Inactive</span>
                    </div>
                </v-col>

                <v-col cols="3">
                    <div class="d-flex">
                        <fe-label class="label toggle">Students Default Inactive</fe-label>
                        <fe-switch
                            v-model="prefs.IMPORT_STUDENTS_INACTIVE"
                            @change="updatePref($event, 'IMPORT_STUDENTS_INACTIVE')"
                        />
                    </div>

                    <div class="d-flex">
                        <fe-label class="label toggle">Enable IE SSO</fe-label>
                        <fe-tooltip :tooltip="'Requires IMT Client ID'" direction="top" :disabled="info.ie_client_uuid ? true : false">
                            <fe-switch
                                    v-model="info.authx_enabled"
                                    :true-value="1"
                                    :false-value="0"
                                    :disabled="!info.ie_client_uuid"
                                    @input="update"
                            />
                        </fe-tooltip>
                    </div>
                </v-col>

                <v-col cols="3">
                    <div class="d-flex">
                        <fe-label class="label toggle">Ed-Fi</fe-label>
                        <fe-switch v-model="info.edfi_enabled" @input="update"/>
                    </div>

                    <div class="d-flex">
                        <fe-label class="label toggle">Utilize Hispanic Indicator</fe-label>
                        <fe-switch
                            v-model="prefs.ENABLE_HISPANIC_INDICATOR"
                            @change="updatePref($event, 'ENABLE_HISPANIC_INDICATOR')"
                        />
                    </div>
                </v-col>
            </v-row>

            <v-row v-if="manageDistrict" class="flex-grow-1">
                <v-col cols="6">
                    <div class="d-flex">
                        <fe-label class="label">Salesforce Account Number</fe-label>
                        <v-text-field v-model="info.sf_number" flat solo dense @change="update"/>
                    </div>
                </v-col>
            </v-row>

            <v-row v-if="manageDistrict" class="flex-grow-1">
                <v-col cols="6">
                    <div class="d-flex">
                        <fe-label class="label">IMT Client ID</fe-label>
                        <v-text-field v-model="info.ie_client_uuid" flat solo dense @change="confirmFieldUpdate('ie_client_uuid')"/>
                    </div>
                </v-col>
            </v-row>
        </div>

        <fe-crud ref="crud" :config="crudConfig" @read="fetched" autoload/>
        <fe-crud ref="prefCrud" :config="prefCrudConfig" @read="prefsFetched" autoload/>
    </v-layout>
</template>

<script>
    import {mapState} from "vuex"

    export default {
        name: "DistrictInfo",

        data() {
            return {
                ready: false,
                updateCallback: {
                    google_calendar_enabled: (v) => this.$store.commit('global/updateDistrictInfo', { flag: 'google_calendar_enabled', value: v ? 1 : 0 }),
                },
                info: {
                    id: undefined,
                    active: false,
                    attendance_enabled: false,
                    city: undefined,
                    dashboard_enabled: false,
                    edfi_enabled: false,
                    email_domain: undefined,
                    alt_domain: undefined,
                    fax_number: undefined,
                    ftp_dir: undefined,
                    google_calendar_enabled: false,
                    hide_score_chart_lines: false,
                    login_format: undefined,
                    login_id: undefined,
                    login_message: undefined,
                    logo_file: undefined,
                    name: undefined,
                    phone_number: undefined,
                    primary_color: undefined,
                    psportal_enabled: false,
                    secondary_color: undefined,
                    state: undefined,
                    street: undefined,
                    time_zone: undefined,
                    upload_dir: undefined,
                    zip: undefined,
                    sftp_username: '',
                    sftp_password: '',
                    sftp_host: '',
                    sftp_port: '',
                    sf_number: undefined,
                    ie_client_uuid: undefined,
                    authx_enabled: undefined
                },
                prefs: {},
                random: '',
                manageDistrict: false,
                showPassword: false,
                setTimeInstance: undefined,
                requiredRule: [v => !_.isEmpty(v) || 'This field is required'],
                rules: {
                    zipcode: {
                        regex: v =>  /^\b\d{5}?\b$/.test(v) || 'Zip code must contain 5 integers.'
                    }

                }
            }
        },

        computed: {
            ...mapState('manage', ['districtPreferences']),

            ...mapState('global', ['hideNav', 'shareableStores']),

            pwdDisplay() {
                let pwd = this.info.sftp_password
                let display = {
                    name: '',
                    set: true
                }
                if (this.$_.isEmpty(pwd)) {
                    display.name = "No Password Set"
                    display.set = false
                } else if (this.showPassword) {
                    display.name = pwd
                } else {
                    display.name = pwd.replace(/./g, '&bull;')
                }
                return display
            },

            crudConfig() {
                return this.$_.cloneDeep(this.$models.districtInfo)
            },

            prefCrudConfig() {
                let cfg = this.$_.cloneDeep(this.$models.preferences)
                cfg.read.params.property = 'district'
                return cfg
            },

        },

        created() {
            this.manageDistrict = this.$checkSecurity('MANAGE_DISTRICT')
        },

        methods: {
            fetched(v) {
                if (v.length) {
                    this.$_.forEach(v[0], (value, key) => {
                        if (this.info.hasOwnProperty(key)) {
                            if (typeof this.info[key] === 'boolean') {
                                this.info[key] = !!value
                            } else {
                                this.info[key] = value
                            }
                        }
                        if (value?.length && (key === 'email_domain' || key === 'alt_domain')) {
                            this.info[key] = value.replace(/^@/g, '');
                        }
                    })

                    this.ready = true

                }

            },

            prefsFetched(v) {
                let prefs = {}
                let hasAdjustedRateValue = false
                v.forEach(pref => {
                    prefs[pref.code] = pref.user_value !== "0"
                    if (pref.code === 'ATTENDANCE_ADJUSTED_RATE') hasAdjustedRateValue = true
                })

                if (!hasAdjustedRateValue) {
                    this.updatePref(false, 'ATTENDANCE_ADJUSTED_RATE')
                    return
                }

                this.prefs = prefs

                this.$store.commit('set', {
                    module: 'manage',
                    state: 'districtPreferences',
                    value: prefs
                })
            },

            editorInput(v) {
                if (this.setTimeInstance) clearTimeout(this.setTimeInstance)
                if (this.$_.isEmpty(v)) {
                    this.setTimeInstance = setTimeout(this.update, 2000)
                }
            },

            update(cb) {
                let updatedInfo = this.$_.cloneDeep(this.info)
                updatedInfo.email_domain = updatedInfo.email_domain.length ? '@' + updatedInfo.email_domain : ''
                updatedInfo.alt_domain = updatedInfo.alt_domain?.length ? '@' + updatedInfo.alt_domain : ''
                if (!updatedInfo.ie_client_uuid) {
                    this.info.authx_enabled = false
                    updatedInfo.authx_enabled = 0
                }
                return this.$refs.crud.update(updatedInfo).then((resp) => {
                    if (cb && resp?.data?.success && this.updateCallback[cb]) {
                        this.updateCallback[cb](this.info[cb] ?? null)
                    }
                })
            },

            // A special version of update that prompts the user before changing
            confirmFieldUpdate(field) {
                let msg = 'Are you sure you want to update this field?'
                if (field == 'ie_client_uuid') {
                    msg = 'Changing the Client ID can impact integrations and lead to incorrect data being shared between clients.  Continue anyway?'
                }

                this.$messageBox({
                    title: 'Confirm Update',
                    persistent: true,
                    message: msg,
                    maxWidth: '500',
                    actions: [{
                        text: 'Cancel',
                        usage: "ghost",
                    }, {
                        text: 'Continue',
                        primary: true,
                        onClick: () => {
                            this.update()
                        }
                    }]
                })
            },

            updatePref(v, id) {
                let data = {}
                data[id] = v ? "1" : "0"
                let prefCrud = this.$refs.prefCrud
                prefCrud.update(data).then(() => {
                    prefCrud.read()
                })
            },

            handleLogoUpload() {
                let logoToUpload = this.$refs.logoInput.files[0];

                if (logoToUpload) {
                    let formData = new FormData()
                    formData.append('logoFile', logoToUpload)

                    this.$axios.post('logoUpload.php', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    })
                        .then(() => {
                            this.random = Math.floor(Math.random() * 1000)
                        })
                }
            },

            updateColor(color, prop) {
                if (prop && color) {
                    if (this.info.hasOwnProperty(prop) && this.info[prop] !== color) {
                        this.info[prop] = color
                        this.update()
                    }
                }
            },

        }
    }
</script>

<style lang="scss" scoped>
    .label {
        width: 125px;
        font-size: 14px;

        &.toggle {
            width: 185px;
        }
    }
</style>
