
/**
* Returns custom demographic name and value as a string for dispaly
*
* @param {array of objects} demos - from $store value global/demographicsSearchable
* @param {array of 2 strings} filter - key[0] and value[1] containting filter name and selected value by user
* @returns {string} the readable value of the demo name and chosen value
*/
export function getDemoName(demos, filter) {
    let demoId = filter[0].split('_')[1]

    let currentDemo = demos.find(demo => demo.id === parseInt(demoId))
    let filterType = currentDemo['value_type']
    let filterChoices

    switch(filterType) {
        case 'boolean':
            filterChoices = filter[1].includes('1') ? 'Yes' : 'No'
            break
        case 'option':
            let splitOptions = filter[1].split(',')
            filterChoices = splitOptions.map(fOpt => currentDemo.options.find(opt => opt.id === parseInt(fOpt)).value)
            filterChoices = filterChoices.length > 1 ? filterChoices.join(', ') : filterChoices
            break
        case 'number':
            let splitChoices = filter[1].split(':')
            filterChoices = splitChoices.length > 2 ? `between ${splitChoices[1]} and ${splitChoices[2]}` : `${splitChoices[0]} ${splitChoices[1]}`
            break
    }

    return `${currentDemo.name}: ${filterChoices}`
}
